import { IANATimezone } from '@supy.api/dictionaries';

import {
  getApiDetailsDecorator,
  getDateInTimeZone,
  getEnumMember,
  IdType,
  isExistent,
  LocalizedSkeletonObjectType,
  SimpleUser,
  SkeletonObjectType,
  SupplierSnapshot,
} from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { NonFunctionProperties } from '@supy/core';

import {
  CkCustomerReturnActivity,
  CkCustomerReturnActivityType,
  CkCustomerReturnMetadata,
  CkCustomerReturnResponse,
  CkCustomerReturnStatus,
  CkCustomerReturnTotals,
} from './ck-customer-return.model';
import { CkCustomerReturnDocument } from './ck-customer-return-document.entity';
import { CkCustomerReturnItem } from './ck-customer-return-item.entity';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnResponse>();

type CkCustomerReturnArgs = Omit<
  NonFunctionProperties<CkCustomerReturn>,
  | 'closedOn'
  | 'isDiscarded'
  | 'isDownloadable'
  | 'isLockable'
  | 'isLocked'
  | 'isPending'
  | 'isPosted'
  | 'isSavable'
  | 'isSaved'
  | 'isStockUpdatable'
  | 'isUnlockable'
>;

export class CkCustomerReturn {
  private constructor(args: CkCustomerReturnArgs) {
    this.activities = args.activities;
    this.comment = args.comment;
    this.createdAt = args.createdAt;
    this.createdBy = args.createdBy;
    this.customer = args.customer;
    this.document = args.document;
    this.id = args.id;
    this.items = args.items;
    this.location = args.location;
    this.metadata = args.metadata;
    this.outlet = args.outlet;
    this.retailer = args.retailer;
    this.status = args.status;
    this.supplier = args.supplier;
    this.totals = args.totals;

    // Computed properties
    this.closedOn = this.activities.find(({ type }) => type === CkCustomerReturnActivityType.Posted)?.createdAt ?? null;
    this.isPosted = this.status === CkCustomerReturnStatus.Posted;
    this.isSaved = this.status === CkCustomerReturnStatus.Saved;
    this.isDownloadable = isExistent(this.id);
    this.isLocked = this.metadata?.isLocked && this.isSaved;
    this.isLockable = this.isSaved && !this.isLocked;
    this.isUnlockable = this.isSaved && this.isLocked;
    this.isPending = this.status === CkCustomerReturnStatus.Pending;
    this.isSavable = !isExistent(this.id);
    this.isDiscarded = this.status === CkCustomerReturnStatus.Discarded;
    this.isStockUpdatable = !this.isPosted;
  }

  @ApiProperty() readonly activities?: CkCustomerReturnActivity[];
  @ApiProperty() readonly comment: string;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly createdBy: SimpleUser;
  @ApiProperty() readonly customer: SkeletonObjectType | null;
  @ApiProperty() readonly document: CkCustomerReturnDocument;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly items: CkCustomerReturnItem[];
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly metadata: CkCustomerReturnMetadata;
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType | null;
  @ApiProperty() readonly retailer: IdType;
  @ApiProperty() readonly status: CkCustomerReturnStatus;
  @ApiProperty() readonly supplier: SupplierSnapshot;
  @ApiProperty() readonly totals: CkCustomerReturnTotals;

  // Computed properties
  readonly closedOn: Date | null;
  readonly isDownloadable: boolean;
  readonly isLocked: boolean;
  readonly isPosted: boolean;
  readonly isSaved: boolean;
  readonly isLockable: boolean;
  readonly isUnlockable: boolean;
  readonly isPending: boolean;
  readonly isSavable: boolean;
  readonly isDiscarded: boolean;
  readonly isStockUpdatable: boolean;

  static deserialize(
    data: CkCustomerReturnResponse,
    {
      ianaTimezone,
    }: {
      readonly ianaTimezone: IANATimezone;
    },
  ): CkCustomerReturn {
    return new CkCustomerReturn({
      id: data.id,
      supplier: data.supplier,
      retailer: data.retailer,
      status: getEnumMember(CkCustomerReturnStatus, data.status),
      document: CkCustomerReturnDocument.deserialize(data.document, { ianaTimezone }),
      activities: data.activities ?? [],
      items: data.items?.map(CkCustomerReturnItem.deserialize) ?? [],
      totals: data.totals,
      createdBy: data.createdBy,
      createdAt: data.createdAt,
      metadata: data.metadata,
      outlet: data.outlet ?? null,
      comment: data.comment ?? null,
      customer: data.customer ?? null,
      location: data.location,
    });
  }

  static deserializeNew({
    supplier,
    customer,
    number,
    ianaTimezone,
  }: DeserializedNewCkCustomerReturnArgs): CkCustomerReturn {
    return new CkCustomerReturn({
      activities: [],
      comment: null,
      createdAt: new Date(),
      createdBy: null,
      document: {
        number,
        returnDate: getDateInTimeZone(new Date(), ianaTimezone),
        attachments: [],
        localFiles: [],
      },
      id: null,
      items: [],
      metadata: null,
      retailer: null,
      status: CkCustomerReturnStatus.New,
      supplier,
      totals: null,
      customer,
      location: null,
      outlet: null,
    });
  }

  static deserializeExisting({ ...props }: CkCustomerReturn): CkCustomerReturn {
    return new CkCustomerReturn({
      ...props,
    });
  }
}

export const CkCustomerReturnStatusNameMapper: Record<CkCustomerReturnStatus, string> = {
  [CkCustomerReturnStatus.Discarded]: $localize`:@@discarded:Discarded`,
  [CkCustomerReturnStatus.New]: $localize`:@@new:New`,
  [CkCustomerReturnStatus.Pending]: $localize`:@@pending:Pending`,
  [CkCustomerReturnStatus.Posted]: $localize`:@@posted:Posted`,
  [CkCustomerReturnStatus.Saved]: $localize`:@@saved:Saved`,
};

export const CkCustomerReturnStatusBadgeMapper: Record<CkCustomerReturnStatus, BadgeStatus> = {
  [CkCustomerReturnStatus.Discarded]: 'light-error',
  [CkCustomerReturnStatus.New]: 'primary',
  [CkCustomerReturnStatus.Pending]: 'primary',
  [CkCustomerReturnStatus.Posted]: 'success',
  [CkCustomerReturnStatus.Saved]: 'info',
};

export type DeserializedNewCkCustomerReturnArgs = Pick<CkCustomerReturn, 'supplier' | 'customer'> &
  Pick<CkCustomerReturnDocument, 'number'> & {
    readonly ianaTimezone: IANATimezone;
  };
