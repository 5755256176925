import produce from 'immer';
import { groupBy } from 'lodash-es';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseResponseMetadata,
  Branch,
  BranchState,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  isOneOf,
  Query,
  QueryBuilder,
} from '@supy/common';
import { DropdownTreeNode } from '@supy/components';
import { Nullable } from '@supy/core';
import { DetailedOrder, OrdersService, OrderStatus } from '@supy/orders';
import { BranchesService, CurrentRetailerState } from '@supy/retailers';
import { getLocalizedName, RetailerSettingsState, SettingsState } from '@supy/settings';

import { CkOrder, CkOrderB2b, CkOrderRequestProps, CkOrdersRequestMetadata } from '../../core';
import { CkOrdersService } from '../../services';
import {
  CkGetRetailerBranches,
  CkOrdersConfirmMany,
  CkOrdersCreateB2b,
  CkOrdersGetB2b,
  CkOrdersGetDetailed,
  CkOrdersGetMany,
  CkOrdersInitFilters,
  CkOrdersPatchFilters,
  CkOrdersPatchRequestMeta,
  CkOrdersRejectMany,
  CkOrdersResetB2b,
  CkOrdersResetFilters,
  CkOrdersSetFilters,
  CkOrdersShipMany,
  CkOrdersUpdate,
  CkOrdersUpdateB2b,
} from '../actions';

export interface CkOrdersFilters {
  readonly branches: string[];
  readonly customers: string[];
  readonly endDeliveryDate: number;
  readonly endOrderDate: number;
  readonly searchFilter: string;
  readonly startDeliveryDate: number;
  readonly startOrderDate: number;
  readonly status: Nullable<OrderStatus>;
  readonly supplierId: string;
}

export interface CkOrdersStateModel extends EntityListStateModel<CkOrder> {
  readonly b2bOrder: CkOrderB2b;
  readonly detailedOrder: DetailedOrder;
  readonly filters: CkOrdersFilters;
  readonly requestMetadata: CkOrdersRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly retailerBranches: Branch[];
}

const DEFAULT_FILTERS: CkOrdersFilters = {
  branches: [],
  customers: [],
  endDeliveryDate: null,
  endOrderDate: null,
  searchFilter: null,
  startDeliveryDate: null,
  startOrderDate: null,
  status: null,
  supplierId: null,
};

const DEFAULT_REQUEST_METADATA: CkOrdersRequestMetadata = {
  page: 0,
  limit: 25,
  retailerId: null,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_ORDERS_STATE_TOKEN = new StateToken<CkOrdersStateModel>('ckOrders');

@State<CkOrdersStateModel>({
  name: CK_ORDERS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    b2bOrder: null,
    detailedOrder: null,
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
    retailerBranches: [],
  },
})
@Injectable()
export class CkOrdersState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #ordersService = inject(OrdersService);
  readonly #ckOrdersService = inject(CkOrdersService);
  readonly #branchesService = inject(BranchesService);
  readonly #store = inject(Store);
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  @Selector()
  static orders(state: CkOrdersStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentOrder(state: CkOrdersStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkOrdersStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkOrdersStateModel) {
    return EntityListState.isLast(state);
  }

  static order(id: string, next?: boolean) {
    return createSelector([CkOrdersState], (state: CkOrdersStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static filters(state: CkOrdersStateModel) {
    return EntityListState.currentFilters<CkOrdersFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkOrdersStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkOrdersStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static detailedOrder(state: CkOrdersStateModel) {
    return state.detailedOrder;
  }

  @Selector()
  static b2bOrder(state: CkOrdersStateModel) {
    return state.b2bOrder;
  }

  @Selector()
  static retailerLocationsAsTree(state: CkOrdersStateModel) {
    const locations: DropdownTreeNode<string>[] = [];
    const groupedOutlets = groupBy(state.retailerBranches, branch => branch.outlet.id);

    for (const key in groupedOutlets) {
      const outlet = groupedOutlets[key].find(({ outlet }) => outlet.id === key).outlet.name;

      locations.push({
        id: key,
        name: getLocalizedName(outlet),
        children: groupedOutlets[key],
        unselectable: true,
      });
    }

    return locations;
  }

  @Action(CkOrdersInitFilters)
  initFilters(ctx: StateContext<CkOrdersStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkOrdersSetFilters)
  setFilters(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersSetFilters) {
    super.setFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkOrdersPatchRequestMeta({ page: 0 }), new CkOrdersGetMany()]);
  }

  @Action(CkOrdersPatchFilters)
  patchFilters(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkOrdersPatchRequestMeta({ page: 0 }), new CkOrdersGetMany()]);
  }

  @Action(CkOrdersResetFilters)
  resetFilters(ctx: StateContext<CkOrdersStateModel>) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      status: ctx.getState().filters.status,
      supplierId: ctx.getState().filters.supplierId,
    });
    ctx.dispatch([new CkOrdersPatchRequestMeta({ page: 0 }), new CkOrdersGetMany()]);
  }

  @Action(CkOrdersPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkOrdersGetDetailed)
  getOrderDetailed(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersGetDetailed) {
    return this.#ordersService.getDetailed(payload.id).pipe(
      withLatestFrom(
        this.#store.selectOnce(CurrentRetailerState.suppliers),
        this.#store.selectOnce(RetailerSettingsState.defaultOnReceivingTax),
        this.#store.selectOnce(RetailerSettingsState.taxes),
      ),
      tap(([response, suppliers, defaultOnReceivingTax, taxRates]) => {
        ctx.patchState({
          detailedOrder: DetailedOrder.deserialize(response, {
            suppliers,
            defaultOnReceivingTax,
            taxRates,
          }),
        });
      }),
    );
  }

  @Action(CkOrdersGetB2b)
  getOrderB2b(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersGetB2b) {
    return this.#ckOrdersService.getB2b(payload.id).pipe(
      tap(response => {
        ctx.patchState({ b2bOrder: CkOrderB2b.deserialize(response) });
      }),
    );
  }

  @Action(CkOrdersResetB2b)
  resetOrderB2b(ctx: StateContext<CkOrdersStateModel>) {
    return ctx.patchState({ b2bOrder: null });
  }

  @Action(CkOrdersGetMany, { cancelUncompleted: true })
  getOrders(ctx: StateContext<CkOrdersStateModel>) {
    return this.#ckOrdersService.getMany(this.getQuery(ctx.getState())).pipe(
      map(({ data, metadata }) => ({
        data: CkOrder.deserializeList(data),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(CkOrdersUpdate)
  updateOrder(ctx: StateContext<CkOrdersStateModel>, { payload: { body, id } }: CkOrdersUpdate) {
    return this.#ordersService.update(id, body).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.entities = draft.entities.filter(entity => entity.id !== id);
          }),
        );
      }),
    );
  }

  @Action(CkOrdersConfirmMany)
  confirmMany(ctx: StateContext<CkOrdersStateModel>, { orderIds }: CkOrdersConfirmMany) {
    return this.#ordersService.confirmMany(orderIds.map(id => ({ id }))).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.entities = draft.entities.filter(({ id }) => !orderIds.includes(id));
          }),
        );
      }),
    );
  }

  @Action(CkOrdersShipMany)
  shipMany(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersShipMany) {
    return this.#ordersService.shipMany(payload).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.entities = draft.entities.filter(({ id }) => !payload.orderIds.map(({ id }) => id).includes(id));
          }),
        );
      }),
    );
  }

  @Action(CkOrdersRejectMany)
  rejectMany(ctx: StateContext<CkOrdersStateModel>, { orderIds }: CkOrdersRejectMany) {
    return this.#ordersService.rejectMany(orderIds.map(id => ({ id }))).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.entities = draft.entities.filter(({ id }) => !orderIds.includes(id));
          }),
        );
      }),
    );
  }

  @Action(CkOrdersCreateB2b)
  createB2b(ctx: StateContext<CkOrdersStateModel>, { payload }: CkOrdersCreateB2b) {
    return this.#ckOrdersService
      .create(payload)
      .pipe(switchMap(result => ctx.dispatch(new CkOrdersGetB2b({ id: result.id }))));
  }

  @Action(CkOrdersUpdateB2b)
  updateB2b(_: StateContext<CkOrdersStateModel>, { orderId, payload }: CkOrdersUpdateB2b) {
    return this.#ckOrdersService.update(orderId, payload);
  }

  @Action(CkGetRetailerBranches)
  getRetailerBranches(ctx: StateContext<CkOrdersStateModel>, { retailerId }: CkGetRetailerBranches) {
    const qb = new QueryBuilder<Branch>({
      filtering: [
        {
          by: 'retailer',
          match: retailerId,
          op: 'eq',
        },
        {
          by: 'state',
          op: 'neq',
          match: BranchState.deleted,
        },
      ],
    });

    return this.#branchesService.getBranches(qb.build()).pipe(
      tap(({ data }) => {
        ctx.patchState({ retailerBranches: data });
      }),
    );
  }

  private getQuery({ filters, requestMetadata }: CkOrdersStateModel): Query<CkOrderRequestProps> {
    const {
      branches,
      customers,
      searchFilter,
      status,
      supplierId,
      startDeliveryDate,
      endDeliveryDate,
      startOrderDate,
      endOrderDate,
    } = filters;
    const utcOffset = this.#utcOffset();

    const qb = new QueryBuilder<CkOrderRequestProps>({
      filtering: [],
      paging: { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
      ordering: [
        {
          by: 'deliveryDate',
          dir: isOneOf(status, [OrderStatus.Submitted, OrderStatus.Confirmed]) ? 'asc' : 'desc',
        },
      ],
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'supplier.metadata.retailerId',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (searchFilter) {
      qb.filtering.withGroup('or', [
        {
          by: 'number',
          match: searchFilter,
          op: 'like',
        },
        {
          by: 'externalDocNumber',
          match: searchFilter,
          op: 'like',
        },
      ]);
    }

    if (branches?.length) {
      qb.filtering.setFilter({
        by: 'branch.id',
        match: branches,
        op: 'in',
      });
    }

    if (customers?.length) {
      qb.filtering.withGroup('or', [
        {
          by: 'customer.id',
          match: customers,
          op: 'in',
        },
        {
          by: 'branch.id',
          match: customers,
          op: 'in',
        },
      ]);
    }

    if (supplierId) {
      qb.filtering.setFilter({
        by: 'supplierId',
        match: supplierId,
        op: 'eq',
      });
    }

    if (status) {
      if (status === OrderStatus.Received) {
        // Delivered tab should be showing both received & partially received orders
        qb.filtering.setFilter({
          by: 'status',
          match: [OrderStatus.Received, OrderStatus.PartialReceived],
          op: 'in',
        });
      } else {
        qb.filtering.setFilter({
          by: 'status',
          match: status,
          op: 'eq',
        });
      }
    }

    if (startOrderDate && endOrderDate) {
      qb.filtering.withFiltering([
        { by: 'createdAt', op: 'lte', match: getShiftedDate(endOrderDate, utcOffset).getTime() },
        { by: 'createdAt', op: 'gte', match: getShiftedDate(startOrderDate, utcOffset).getTime() },
      ]);
    }

    if (startDeliveryDate && endDeliveryDate) {
      const by = isOneOf(status, [OrderStatus.Shipped, OrderStatus.Received, OrderStatus.PartialReceived])
        ? 'shipDate'
        : 'deliveryDate';

      qb.filtering.withFiltering([
        {
          by,
          op: 'lte',
          match: getShiftedDate(endDeliveryDate, utcOffset).getTime(),
        },
        { by, op: 'gte', match: getShiftedDate(startDeliveryDate, utcOffset).getTime() },
      ]);
    }

    return qb.build();
  }
}
