import { getApiDetailsDecorator, IdType, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { StatefulOrderEntity } from '@supy/orders';

import { CkAggregatedOrderResponse } from './ck-order-item.model';

const ApiProperty = getApiDetailsDecorator<CkAggregatedOrderResponse>();

type CkAggregatedOrderArgs = Omit<
  NonFunctionProperties<CkAggregatedOrder>,
  'confirmedQuantity' | 'actualPrice' | 'orderedQuantity'
>;

export class CkAggregatedOrder {
  private constructor(args: CkAggregatedOrderArgs) {
    this.quantity = args.quantity;
    this.id = args.id;
    this.location = args.location;
    this.number = args.number;
    this.price = args.price;

    // Computed properties
    this.actualPrice = this.price.confirmed ?? this.price.ordered;
    this.branchName = args.branchName;
    this.confirmedQuantity = this.quantity.modified ?? this.quantity.ordered;
    this.orderedQuantity = this.quantity.ordered;

    this.externalDocNumber = args.externalDocNumber;
  }

  @ApiProperty() readonly location: IdType;
  @ApiProperty() readonly price: StatefulOrderEntity;
  @ApiProperty() readonly quantity: StatefulOrderEntity;
  @ApiProperty({ key: 'orderId' }) readonly id: string;
  @ApiProperty({ key: 'orderNumber' }) readonly number: string;

  // Computed properties
  readonly actualPrice: number;
  readonly branchName: LocalizedData;
  readonly confirmedQuantity: number;
  readonly orderedQuantity: number;
  readonly externalDocNumber?: string;

  static deserialize(data: CkAggregatedOrderResponse): CkAggregatedOrder {
    return new CkAggregatedOrder({
      quantity: data.quantity,
      id: data.orderId,
      location: data.location,
      number: data.orderNumber,
      price: data.price,
      branchName: data.location.branch.name as LocalizedData,
      externalDocNumber: data.externalDocNumber,
    });
  }

  static deserializeList(data: CkAggregatedOrderResponse[]): CkAggregatedOrder[] {
    return data.map(order => this.deserialize(order));
  }
}
