import { TaxRate } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { DiscountType } from '@supy/orders';

import { CkCatalogItemPricingMethod } from '../catalog';
import { CreateCkOrderItemB2bRequest, UpdateCkOrderItemB2bRequest } from './ck-order-item-b2b.model';

export class CkOrderItemB2bPayload {
  constructor(args: NonFunctionProperties<CkOrderItemB2bPayload>) {
    this.id = args.id;
    this.catalogItemId = args.catalogItemId;
    this.price = args.price;
    this.quantity = args.quantity;
    this.confirmedQuantity = args.confirmedQuantity;
    this.discountType = args.discountType;
    this.discountAmount = args.discountAmount;
    this.tax = args.tax;
    this.pricingMethod = args.pricingMethod;
  }

  readonly catalogItemId: string;
  readonly confirmedQuantity: number;
  readonly discountAmount: number;
  readonly discountType: DiscountType;
  readonly id: string;
  readonly price: number;
  readonly pricingMethod: CkCatalogItemPricingMethod;
  readonly quantity: number;
  readonly tax: TaxRate;

  serializeCreate(): CreateCkOrderItemB2bRequest {
    return {
      ckItemId: this.catalogItemId,
      price: this.price,
      discount: {
        type: this.discountType,
        amount: this.discountAmount ?? 0,
      },
      quantity: this.quantity,
      taxCode: this.tax.taxCode,
      pricingMethod: this.pricingMethod,
    };
  }

  serializeUpdate(): UpdateCkOrderItemB2bRequest {
    return {
      ...this.serializeCreate(),
      quantity: this.confirmedQuantity ?? this.quantity,
      id: this.id,
    };
  }
}
