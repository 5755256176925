import { BaseRequestMetadata, IdType, SimpleUser } from '@supy/common';
import { ShippingInvoiceType } from '@supy/settings';

export enum CkCustomerGroupState {
  Available = 'available',
  Archived = 'archived',
}

export interface CkCustomerGroupResponse {
  readonly id: string;
  readonly name: string;
  readonly state: CkCustomerGroupState;
  readonly createdBy: SimpleUser;
  readonly defaultTaxCode?: string;
  readonly shippingInvoiceType?: ShippingInvoiceType;
}

export interface CkCustomerGroupRequest {
  readonly name: string;
  readonly defaultTaxCode?: string;
  readonly shippingInvoiceType: ShippingInvoiceType;
}

export interface CkCustomerGroupCreateRequest extends CkCustomerGroupRequest {
  readonly retailer: IdType;
}

export type CkCustomerGroupUpdateRequest = Pick<CkCustomerGroupCreateRequest, 'name'>;

export interface CkCustomerGroupsQueryProps {
  readonly state: string;
  readonly name: string;
  readonly id: string;
  readonly 'retailer.id': string;
}

export interface CkCustomerGroupsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
}
