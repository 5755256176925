import produce from 'immer';
import { switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import { CkCatalogItemsQueryProps, CkCustomerItem, CkCustomerItemsRequestMetadata } from '../../core';
import { downloadCkCustomerItemsList } from '../../helpers';
import { CkCustomersService } from '../../services';
import {
  CkCustomerItemsExport,
  CkCustomerItemsGetMany,
  CkCustomerItemsInitFilters,
  CkCustomerItemsPatchFilters,
  CkCustomerItemsPatchRequestMetadata,
  CkCustomerItemsResetFilters,
  CkCustomerItemsUpdate,
  CkCustomersGet,
} from '../actions';
import { CkCustomersState } from './ck-customers.state';

export interface CkCustomerItemsFilters {
  readonly search: string;
  readonly centralKitchenId: string;
  readonly itemCategoryIds: string[];
}

export interface CkCustomerItemsStateModel extends EntityListStateModel<CkCustomerItem> {
  readonly filters: CkCustomerItemsFilters;
  readonly requestMetadata: CkCustomerItemsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkCustomerItemsFilters = {
  search: null,
  centralKitchenId: null,
  itemCategoryIds: [],
};

const DEFAULT_REQUEST_METADATA: CkCustomerItemsRequestMetadata = {
  retailerId: null,
  customer: null,
  warehouseId: null,
  page: 0,
  limit: 25,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_CUSTOMER_Items_STATE_TOKEN = new StateToken<CkCustomerItemsStateModel>('ckCustomerItems');

@State<CkCustomerItemsStateModel>({
  name: CK_CUSTOMER_Items_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class CkCustomerItemsState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #customersService = inject(CkCustomersService);
  readonly #store = inject(Store);
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));

  @Selector() static items(state: CkCustomerItemsStateModel) {
    return EntityListState.all(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([CK_CUSTOMER_Items_STATE_TOKEN], (state: CkCustomerItemsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static currentItem(state: CkCustomerItemsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkCustomerItemsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkCustomerItemsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static filters(state: CkCustomerItemsStateModel) {
    return EntityListState.currentFilters<CkCustomerItemsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkCustomerItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkCustomerItemsStateModel) {
    return state.responseMetadata;
  }

  @Action(CkCustomerItemsInitFilters)
  initFilters(ctx: StateContext<CkCustomerItemsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkCustomerItemsPatchFilters)
  patchFilters(ctx: StateContext<CkCustomerItemsStateModel>, { payload }: CkCustomerItemsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkCustomerItemsPatchRequestMetadata({ page: 0 }), new CkCustomerItemsGetMany()]);
  }

  @Action(CkCustomerItemsResetFilters)
  resetFilters(ctx: StateContext<CkCustomerItemsStateModel>) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      centralKitchenId: ctx.getState().filters.centralKitchenId,
    });
  }

  @Action(CkCustomerItemsPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<CkCustomerItemsStateModel>, { payload }: CkCustomerItemsPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkCustomerItemsGetMany)
  getMany(ctx: StateContext<CkCustomerItemsStateModel>) {
    const { customer } = ctx.getState().requestMetadata;

    return ctx.dispatch(new CkCustomersGet({ id: customer.id })).pipe(
      switchMap(() => this.#customersService.getItems(customer.id, this.getQuery(ctx.getState()))),
      withLatestFrom(this.#store.select(CkCustomersState.currentCustomer)),
      tap(([{ data, metadata }, customer]) => {
        ctx.patchState({
          responseMetadata: metadata,
        });
        super.setMany(
          ctx,
          data.map(item => CkCustomerItem.deserialize(item, { customer })),
        );
      }),
    );
  }

  @Action(CkCustomerItemsUpdate)
  update(ctx: StateContext<CkCustomerItemsStateModel>, { payload }: CkCustomerItemsUpdate) {
    const { customer } = ctx.getState().requestMetadata;

    return this.#customersService.updateItems(customer.id, payload);
  }

  @Action(CkCustomerItemsExport)
  export(ctx: StateContext<CkCustomerItemsStateModel>, { payload: { customer } }: CkCustomerItemsExport) {
    const query = this.getQuery(ctx.getState(), {
      noLimit: true,
    });

    return this.#customersService.getItems(customer.id, query).pipe(
      withLatestFrom(this.#store.selectOnce(SettingsState.currency)),
      tap(([{ data }, currency]) => {
        void downloadCkCustomerItemsList(
          data.map(item => CkCustomerItem.deserialize(item, { customer })),
          { currency, hidePrices: this.#hidePrices() },
        );
      }),
    );
  }

  private getQuery(
    { filters, requestMetadata }: CkCustomerItemsStateModel,
    options?: { noLimit: boolean },
  ): Query<CkCatalogItemsQueryProps> {
    const qb = new QueryBuilder<CkCatalogItemsQueryProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
      ordering: [
        {
          by: 'category.id',
          dir: 'asc',
        },
        {
          by: 'packaging.name',
          dir: 'asc',
        },
      ],
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (requestMetadata.customer) {
      qb.filtering.setFilter({
        by: 'priceLists.id',
        match: requestMetadata.customer.priceList.id,
        op: 'eq',
      });
    }

    if (filters.search) {
      qb.filtering.setFilter({
        by: 'packaging.name',
        match: filters.search,
        op: 'like',
      });
    }

    if (filters.centralKitchenId) {
      qb.filtering.setFilter({
        by: 'centralKitchen.locationId',
        match: filters.centralKitchenId,
        op: 'eq',
      });
    }

    if (filters.itemCategoryIds?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: decodeURIComponent(filters.itemCategoryIds as unknown as string).split(','),
        op: 'in',
      });
    }

    return qb.build();
  }
}
