import produce from 'immer';
import { switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import {
  CkCatalogItemsQueryProps,
  CkCatalogItemState,
  CkCatalogPackaging,
  CkPriceListItem,
  CkPriceListItemsRequestMetadata,
} from '../../core';
import { downloadCkAllCatalogList, downloadCkPriceListItemsList } from '../../helpers';
import { CkPriceListsService } from '../../services';
import {
  CkPriceListItemsDeleteMany,
  CkPriceListItemsExport,
  CkPriceListItemsExportAllCatalog,
  CkPriceListItemsGetMany,
  CKPriceListItemsImport,
  CkPriceListItemsInitFilters,
  CkPriceListItemsPatchFilters,
  CkPriceListItemsPatchRequestMetadata,
  CkPriceListItemsResetFilters,
  CkPriceListItemUpdateMany,
  CkPriceListsGet,
} from '../actions';
import { CkPriceListsState } from './ck-price-lists.state';

export interface CkPriceListItemsFilters {
  readonly search: string;
  readonly centralKitchenId: string;
  readonly itemCategoryIds: string[];
}

export interface CkPriceListItemsStateModel extends EntityListStateModel<CkPriceListItem> {
  readonly exportableItems: Array<CkCatalogPackaging | CkPriceListItem>;
  readonly filters: CkPriceListItemsFilters;
  readonly requestMetadata: CkPriceListItemsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkPriceListItemsFilters = {
  search: null,
  centralKitchenId: null,
  itemCategoryIds: [],
};

const DEFAULT_REQUEST_METADATA: CkPriceListItemsRequestMetadata = {
  retailerId: null,
  priceListId: null,
  warehouseId: null,
  page: 0,
  limit: 10,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_PRICE_LIST_ITEMS_STATE_TOKEN = new StateToken<CkPriceListItemsStateModel>('ckPriceListItems');

@State<CkPriceListItemsStateModel>({
  name: CK_PRICE_LIST_ITEMS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    exportableItems: [],
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class CkPriceListItemsState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #priceListsService = inject(CkPriceListsService);
  readonly #store = inject(Store);
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));

  @Selector() static items(state: CkPriceListItemsStateModel) {
    return EntityListState.all(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([CK_PRICE_LIST_ITEMS_STATE_TOKEN], (state: CkPriceListItemsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static currentItem(state: CkPriceListItemsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkPriceListItemsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkPriceListItemsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static filters(state: CkPriceListItemsStateModel) {
    return EntityListState.currentFilters<CkPriceListItemsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkPriceListItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkPriceListItemsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static exportableItems(state: CkPriceListItemsStateModel) {
    return state.exportableItems;
  }

  @Action(CkPriceListItemsInitFilters)
  initFilters(ctx: StateContext<CkPriceListItemsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkPriceListItemsPatchFilters)
  patchFilters(ctx: StateContext<CkPriceListItemsStateModel>, { payload }: CkPriceListItemsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkPriceListItemsPatchRequestMetadata({ page: 0 }), new CkPriceListItemsGetMany()]);
  }

  @Action(CkPriceListItemsResetFilters)
  resetFilters(ctx: StateContext<CkPriceListItemsStateModel>) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      centralKitchenId: ctx.getState().filters.centralKitchenId,
    });

    ctx.dispatch([new CkPriceListItemsPatchRequestMetadata({ page: 0 }), new CkPriceListItemsGetMany()]);
  }

  @Action(CkPriceListItemsPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<CkPriceListItemsStateModel>,
    { payload }: CkPriceListItemsPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkPriceListItemsGetMany)
  getMany(ctx: StateContext<CkPriceListItemsStateModel>) {
    const { priceListId } = ctx.getState().requestMetadata;

    return ctx.dispatch(new CkPriceListsGet({ id: priceListId })).pipe(
      switchMap(() => this.#priceListsService.getItems(priceListId, this.getQuery(ctx.getState()))),
      withLatestFrom(this.#store.select(CkPriceListsState.currentPriceList)),
      tap(([{ data, metadata }, priceList]) => {
        ctx.patchState({
          responseMetadata: metadata,
        });
        super.setMany(
          ctx,
          data.map(priceListItem => CkPriceListItem.deserialize(priceListItem, { priceList })),
        );
      }),
    );
  }

  @Action(CkPriceListItemUpdateMany)
  updateMany(_: StateContext<CkPriceListItemsStateModel>, { id, payload }: CkPriceListItemUpdateMany) {
    return this.#priceListsService.updateItems(id, payload);
  }

  @Action(CkPriceListItemsDeleteMany)
  deleteMany(ctx: StateContext<CkPriceListItemsStateModel>, { id, payload }: CkPriceListItemsDeleteMany) {
    return this.#priceListsService.deleteManyItems(id, payload);
  }

  @Action(CkPriceListItemsExport)
  export(ctx: StateContext<CkPriceListItemsStateModel>, { payload: { priceList } }: CkPriceListItemsExport) {
    const query = this.getQuery(ctx.getState(), {
      noLimit: true,
    });

    return this.#priceListsService.getItems(priceList.id, query).pipe(
      withLatestFrom(this.#store.selectOnce(SettingsState.currency)),
      tap(([{ data }, currency]) => {
        void downloadCkPriceListItemsList(
          data.map(priceListItem => CkPriceListItem.deserialize(priceListItem, { priceList })),
          { currency, hidePrices: this.#hidePrices() },
        );
      }),
    );
  }

  @Action(CkPriceListItemsExportAllCatalog)
  exportAllCatalogItems(
    ctx: StateContext<CkPriceListItemsStateModel>,
    { payload: { priceList, withoutDownload } }: CkPriceListItemsExportAllCatalog,
  ) {
    return this.#priceListsService.exportAllCatalogItems(priceList.id, ctx.getState().filters.centralKitchenId).pipe(
      withLatestFrom(
        this.#store.selectOnce(CkPriceListsState.priceLists),
        this.#store.selectOnce(SettingsState.currency),
      ),
      tap(([response, priceLists, currency]) => {
        const deserializedItems = response.map(item =>
          'baseItem' in item
            ? CkCatalogPackaging.deserialize(item, { priceLists })
            : CkPriceListItem.deserialize(item, { priceList }),
        );

        ctx.setState(
          produce(draft => {
            draft.exportableItems = deserializedItems;
          }),
        );

        if (!withoutDownload) {
          void downloadCkAllCatalogList(deserializedItems, { currency, hidePrices: this.#hidePrices() });
        }
      }),
    );
  }

  @Action(CKPriceListItemsImport)
  importItems(ctx: StateContext<CkPriceListItemsStateModel>, { payload }: CKPriceListItemsImport) {
    return this.#priceListsService.importItems(payload).pipe(
      tap(() => {
        ctx.patchState({ exportableItems: [] });
      }),
    );
  }

  private getQuery(
    { filters, requestMetadata }: CkPriceListItemsStateModel,
    options?: { noLimit: boolean },
  ): Query<CkCatalogItemsQueryProps> {
    const qb = new QueryBuilder<CkCatalogItemsQueryProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
      ordering: [
        {
          by: 'category.id',
          dir: 'asc',
        },
        {
          by: 'packaging.name',
          dir: 'asc',
        },
      ],
    });

    qb.filtering.setFilter({
      by: 'state',
      match: CkCatalogItemState.Available,
      op: 'eq',
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (filters.search) {
      qb.filtering.setFilter({
        by: 'packaging.name',
        match: filters.search,
        op: 'like',
      });
    }

    if (filters.centralKitchenId) {
      qb.filtering.setFilter({
        by: 'centralKitchen.locationId',
        match: filters.centralKitchenId,
        op: 'eq',
      });
    }

    if (filters.itemCategoryIds?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: decodeURIComponent(filters.itemCategoryIds as unknown as string).split(','),
        op: 'in',
      });
    }

    return qb.build();
  }
}
