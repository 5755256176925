import { getApiDetailsDecorator, IdType, PartyInfo, PreferredType, SimpleUser } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Country } from '@supy/countries';
import {
  CreationSourceEnum,
  deserializeOrderActivity,
  OrderActivity,
  OrderItem,
  OrderStatus,
  OrderUpdateStatus,
  SimpleChannel,
} from '@supy/orders';

import { CkOrderCustomerSnapshot, CkOrderResponse } from './ck-order.model';

const ApiProperty = getApiDetailsDecorator<CkOrderResponse>();

export class CkOrder {
  private constructor(
    args: NonFunctionProperties<Omit<CkOrder, 'draftedBy' | 'receivedAt' | 'submittedAt' | 'submittedBy'>>,
  ) {
    this.activities = args.activities;
    this.appliedTax = args.appliedTax;
    this.channel = args.channel;
    this.channelPreferredType = args.channelPreferredType;
    this.comment = args.comment;
    this.country = args.country;
    this.createdAt = args.createdAt;
    this.creationSource = args.creationSource;
    this.customer = args.customer;
    this.deliveryDate = args.deliveryDate;
    this.id = args.id;
    this.linkedGrnIds = args.linkedGrnIds;
    this.modifiedCkItems = args.modifiedCkItems;
    this.modifiedItems = args.modifiedItems;
    this.modifiedItemsTotal = args.modifiedItemsTotal;
    this.number = args.number;
    this.orderedCkItems = args.orderedCkItems;
    this.orderedItems = args.orderedItems;
    this.orderedItemsTotal = args.orderedItemsTotal;
    this.partyInfo = args.partyInfo;
    this.retailerId = args.retailerId;
    this.shipDate = args.shipDate;
    this.status = args.status;
    this.supplierId = args.supplierId;
    this.updatedAt = args.updatedAt;

    // Computed properties
    this.draftedBy = this.activities.find(({ action }) => action === OrderUpdateStatus.Draft)?.user ?? null;
    this.receivedAt = this.activities.find(({ action }) => action === OrderUpdateStatus.Received)?.createdAt ?? null;
    this.submittedAt =
      this.activities.find(({ action }) => action == OrderUpdateStatus.Submitted)?.createdAt ?? this.createdAt;
    this.submittedBy = this.activities.find(({ action }) => action == OrderUpdateStatus.Submitted)?.user ?? null;
    this.externalDocNumber = args.externalDocNumber;
  }

  @ApiProperty() readonly activities: OrderActivity[];
  @ApiProperty() readonly appliedTax: number;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly channelPreferredType: PreferredType;
  @ApiProperty() readonly comment: string | null;
  @ApiProperty() readonly country: Country;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly creationSource: CreationSourceEnum;
  @ApiProperty() readonly customer: CkOrderCustomerSnapshot;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly externalDocNumber: string;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly partyInfo: PartyInfo;
  @ApiProperty() readonly retailerId: string;
  @ApiProperty() readonly shipDate: Date;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty() readonly supplierId: string;
  @ApiProperty() readonly updatedAt: Date;
  @ApiProperty({ key: 'linkedGrns' }) readonly linkedGrnIds: IdType[];
  @ApiProperty({ key: 'modified' }) readonly modifiedItems: OrderItem[];
  @ApiProperty({ key: 'modifiedCKItems' }) readonly modifiedCkItems: OrderItem[];
  @ApiProperty({ key: 'modifiedTotal' }) readonly modifiedItemsTotal: number;
  @ApiProperty({ key: 'ordered' }) readonly orderedItems: OrderItem[];
  @ApiProperty({ key: 'orderedCKItems' }) readonly orderedCkItems: OrderItem[];
  @ApiProperty({ key: 'orderedTotal' }) readonly orderedItemsTotal: number;

  // Computed properties
  readonly draftedBy: SimpleUser | null;
  readonly receivedAt: Date | null;
  readonly submittedAt: Date | null;
  readonly submittedBy: SimpleUser | null;

  static deserialize(data: CkOrderResponse): CkOrder {
    return new CkOrder({
      activities: data.activities?.map(deserializeOrderActivity),
      appliedTax: data.appliedTax,
      channel: data.channel,
      channelPreferredType: data.channelPreferredType,
      comment: data.comment ?? null,
      country: data.country,
      createdAt: new Date(data.createdAt),
      creationSource: data.creationSource,
      customer: data.customer,
      deliveryDate: new Date(data.deliveryDate),
      externalDocNumber: data.externalDocNumber ?? null,
      id: data.id,
      linkedGrnIds: data.linkedGrns,
      modifiedCkItems: data.modifiedCKItems ?? [],
      modifiedItems: data.modified ?? [],
      modifiedItemsTotal: data.modifiedTotal,
      number: data.number,
      orderedCkItems: data.orderedCKItems,
      orderedItems: data.ordered,
      orderedItemsTotal: data.orderedTotal,
      partyInfo: data.partyInfo,
      retailerId: data.retailerId,
      shipDate: new Date(data.shipDate ?? data.deliveryDate),
      status: data.status,
      supplierId: data.supplierId,
      updatedAt: new Date(data.updatedAt),
    });
  }

  static deserializeList(data: CkOrderResponse[]): CkOrder[] {
    return data.map(this.deserialize);
  }
}
