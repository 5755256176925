import { difference } from 'lodash-es';
import { IANATimezone } from '@supy.api/dictionaries';

import { getRetailerTimeZoneShiftedDate, removeEmpty, Supplier } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { DiscountType, OrderStatus, OrderUpdateStatus } from '@supy/orders';

import { CkCustomer } from '../customer';
import { CkOrderB2b } from './ck-order-b2b.entity';
import { CkOrderB2bRequest, CreateCkOrderB2bRequest, UpdateCkOrderB2bRequest } from './ck-order-b2b.model';
import { CkOrderItemB2b } from './ck-order-item-b2b.entity';
import { CreateCkOrderItemB2bRequest, UpdateCkOrderItemB2bRequest } from './ck-order-item-b2b.model';
import { CkOrderItemB2bPayload } from './ck-order-item-b2b.payload';

export class CkOrderB2bPayload {
  constructor(args: NonFunctionProperties<CkOrderB2bPayload>) {
    this.centralKitchen = args.centralKitchen;
    this.comments = args.comments;
    this.customer = args.customer;
    this.externalDocNumber = args.externalDocNumber;
    this.ianaTimezone = args.ianaTimezone;
    this.invoiceDiscountAmount = args.invoiceDiscountAmount;
    this.invoiceDiscountType = args.invoiceDiscountType;
    this.invoiceDueDate = args.invoiceDueDate;
    this.items = args.items;
    this.otherFees = args.otherFees;
    this.shipDate = args.shipDate;
    this.updateStock = args.updateStock;
    this.utcOffset = args.utcOffset;
  }

  readonly centralKitchen: Supplier;
  readonly comments: string[];
  readonly customer: CkCustomer;
  readonly externalDocNumber: string;
  readonly ianaTimezone: IANATimezone;
  readonly invoiceDiscountAmount: number;
  readonly invoiceDiscountType: DiscountType;
  readonly invoiceDueDate: Date;
  readonly items: CkOrderItemB2b[];
  readonly otherFees: number;
  readonly shipDate: Date;
  readonly updateStock?: boolean;
  readonly utcOffset: number;

  serialize(): CkOrderB2bRequest {
    return removeEmpty<CkOrderB2bRequest>({
      invoiceDueDate: this.invoiceDueDate
        ? getRetailerTimeZoneShiftedDate(this.invoiceDueDate, this.ianaTimezone, this.utcOffset)
        : null,
      discount:
        this.invoiceDiscountType || this.invoiceDiscountAmount
          ? {
              type: this.invoiceDiscountType ?? DiscountType.Value,
              amount: this.invoiceDiscountAmount ?? 0,
            }
          : null,
      externalDocNumber: this.externalDocNumber,
      fees: this.otherFees,
      comments: this.comments,
      updateStock: this.updateStock,
      shipDate: getRetailerTimeZoneShiftedDate(this.shipDate, this.ianaTimezone, this.utcOffset),
    });
  }
}

export class CreateCkOrderB2bPayload extends CkOrderB2bPayload {
  constructor(args: NonFunctionProperties<CreateCkOrderB2bPayload>) {
    super(args);

    this.status = args.status;
  }

  readonly status: OrderStatus;

  serialize(): CreateCkOrderB2bRequest {
    return removeEmpty<CreateCkOrderB2bRequest>({
      ...super.serialize(),
      status: this.status,
      centralKitchenId: this.centralKitchen.id,
      customerId: this.customer.id,
      items: this.items.map(item => new CkOrderItemB2bPayload(item).serializeCreate()),
    });
  }
}

export class UpdateCkOrderB2bPayload extends CkOrderB2bPayload {
  constructor(args: NonFunctionProperties<UpdateCkOrderB2bPayload>) {
    super(args);

    this.status = args.status;
    this.order = args.order;
  }

  readonly status: OrderUpdateStatus;
  readonly order: CkOrderB2b;

  serialize(): UpdateCkOrderB2bRequest {
    const orderItemIds = new Set(this.items.map(({ id }) => id));
    const existingOrderItems = this.order.items;
    const existingOrderItemIds = new Set(existingOrderItems.map(({ id }) => id));

    const newlyCreatedItems = this.items.reduce<CreateCkOrderItemB2bRequest[]>((acc, item) => {
      if (!existingOrderItemIds.has(item.id)) {
        acc.push(new CkOrderItemB2bPayload(item).serializeCreate());
      }

      return acc;
    }, []);

    const removedItems = existingOrderItems.reduce<UpdateCkOrderItemB2bRequest[]>((acc, item) => {
      if (!orderItemIds.has(item.id)) {
        acc.push(
          new CkOrderItemB2bPayload({
            ...item,
            confirmedQuantity: 0,
          }).serializeUpdate(),
        );
      }

      return acc;
    }, []);

    const modifiedItems = this.items.reduce<UpdateCkOrderItemB2bRequest[]>((acc, item) => {
      if (existingOrderItemIds.has(item.id)) {
        acc.push(new CkOrderItemB2bPayload(item).serializeUpdate());
      }

      return acc;
    }, removedItems);

    return removeEmpty<UpdateCkOrderB2bRequest>({
      ...super.serialize(),
      comments: difference(this.comments, this.order.comments),
      status: this.status,
      items: {
        created: newlyCreatedItems,
        modified: modifiedItems,
      },
    });
  }
}

export class ShipCkOrdersB2bPayload extends UpdateCkOrderB2bPayload {
  constructor(args: NonFunctionProperties<ShipCkOrdersB2bPayload>) {
    super(args);
    this.updateStock = args.updateStock;
  }

  readonly updateStock?: boolean;

  serialize(): UpdateCkOrderB2bRequest {
    return {
      ...super.serialize(),
      updateStock: this.updateStock,
    };
  }
}
