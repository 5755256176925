import produce from 'immer';
import { catchError, EMPTY, map, switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  Query,
  QueryBuilder,
} from '@supy/common';
import { BaseActivity } from '@supy/components';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import {
  CkCustomerReturn,
  CkCustomerReturnPostManyResponse,
  CkCustomerReturnQueryProps,
  CkCustomerReturnStatus,
} from '../../core';
import { downloadCkCustomerReturnsList } from '../../helpers';
import { CkCustomerReturnsService } from '../../services';
import {
  CkCustomerReturensNavigateToList,
  CkCustomerReturnsApprove,
  CkCustomerReturnsApproveMany,
  CkCustomerReturnsCreate,
  CkCustomerReturnsDiscard,
  CkCustomerReturnsDiscardMany,
  CkCustomerReturnsDownloadPdf,
  CkCustomerReturnsExportList,
  CkCustomerReturnsGenerateDocumentNumber,
  CkCustomerReturnsGet,
  CkCustomerReturnsGetMany,
  CkCustomerReturnsInitFilters,
  CkCustomerReturnsLock,
  CkCustomerReturnsLockMany,
  CkCustomerReturnsNavigateToDetails,
  CkCustomerReturnsPatchFilters,
  CkCustomerReturnsPatchRequestMetadata,
  CkCustomerReturnsPost,
  CkCustomerReturnsPostMany,
  CkCustomerReturnsResetFilters,
  CkCustomerReturnsResetPostManyResponse,
  CkCustomerReturnsSetFilters,
  CkCustomerReturnsUnlock,
  CkCustomerReturnsUpdate,
} from '../actions';

export interface CkCustomerReturnsFilters {
  readonly centralKitchenId: string | null;
  readonly customerIds: string[];
  readonly documentNumber: string;
  readonly end?: number | null;
  readonly start?: number | null;
  readonly status: CkCustomerReturnStatus;
}

export interface CkCustomerReturnsStateModel extends EntityListStateModel<CkCustomerReturn> {
  readonly exportLoading: boolean;
  readonly filters: CkCustomerReturnsFilters;
  readonly postManyResponse?: CkCustomerReturnPostManyResponse;
  readonly generatedDocumentNumber?: string;
  requestMetadata: CkCustomerReturnsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

export interface CkCustomerReturnsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
}

const FILTERS_DEFAULT: CkCustomerReturnsFilters = {
  status: null,
  customerIds: [],
  documentNumber: null,
  centralKitchenId: null,
  start: null,
  end: null,
};

const REQUEST_META_DEFAULT = { page: 0, limit: 25, retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

const CK_CUSTOMER_RETURNS_STATE_TOKEN = new StateToken<CkCustomerReturnsStateModel[]>('ckCustomerReturns');

@State<CkCustomerReturnsStateModel>({
  name: CK_CUSTOMER_RETURNS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    exportLoading: false,
  },
})
@Injectable()
export class CkCustomerReturnsState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #store = inject(Store);
  readonly #ckCustomerReturnsService = inject(CkCustomerReturnsService);
  readonly #utcOffset = toSignal(this.#store.select(SettingsState.utcOffset));
  readonly #ianaTimezone = toSignal(this.#store.select(SettingsState.ianaTimeZone));
  readonly #currency = toSignal(this.#store.select(SettingsState.currency));
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));

  @Selector()
  static customerReturns(state: CkCustomerReturnsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentCustomerReturn(state: CkCustomerReturnsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static filters(state: CkCustomerReturnsStateModel) {
    return EntityListState.currentFilters<CkCustomerReturnsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkCustomerReturnsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkCustomerReturnsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static postManyResponse(state: CkCustomerReturnsStateModel) {
    return state.postManyResponse;
  }

  @Selector()
  static isFirst(state: CkCustomerReturnsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkCustomerReturnsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static exportLoading(state: CkCustomerReturnsStateModel) {
    return state.exportLoading;
  }

  @Selector()
  static activities(state: CkCustomerReturnsStateModel) {
    const customerReturn = CkCustomerReturnsState.currentCustomerReturn(state);

    return customerReturn?.activities?.reduce<BaseActivity[]>(
      (acc, cur) => [
        {
          action: cur.type,
          user: cur.user,
          createdAt: cur.createdAt,
        },
        ...acc,
      ],
      [],
    );
  }

  @Selector()
  static generatedDocumentNumber(state: CkCustomerReturnsStateModel) {
    return state.generatedDocumentNumber;
  }

  static customerReturn(id: string, next?: boolean) {
    return createSelector([CkCustomerReturnsState], (state: CkCustomerReturnsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Action(CkCustomerReturnsInitFilters)
  initFilters(ctx: StateContext<CkCustomerReturnsStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(CkCustomerReturnsResetPostManyResponse)
  clearPostManyResponse(ctx: StateContext<CkCustomerReturnsStateModel>) {
    return ctx.patchState({ postManyResponse: null });
  }

  @Action(CkCustomerReturnsSetFilters)
  setFilters(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsSetFilters) {
    super.setFilter(ctx, payload, FILTERS_DEFAULT);

    ctx.dispatch([new CkCustomerReturnsPatchRequestMetadata({ page: 0 }), new CkCustomerReturnsGetMany()]);
  }

  @Action(CkCustomerReturnsPatchFilters)
  patchFilters(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsPatchFilters) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT);

    ctx.dispatch([new CkCustomerReturnsPatchRequestMetadata({ page: 0 }), new CkCustomerReturnsGetMany()]);
  }

  @Action(CkCustomerReturnsResetFilters)
  resetFilters(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsResetFilters) {
    super.resetFilter(ctx, {
      ...FILTERS_DEFAULT,
      ...payload,
    });

    ctx.dispatch([new CkCustomerReturnsPatchRequestMetadata({ page: 0 }), new CkCustomerReturnsGetMany()]);
  }

  @Action(CkCustomerReturnsPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<CkCustomerReturnsStateModel>,
    { payload }: CkCustomerReturnsPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...draft.requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkCustomerReturnsGet)
  get(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsGet) {
    return super.getOne(ctx, {
      ...payload,
      fetchApi: () =>
        this.#ckCustomerReturnsService.get(payload.id).pipe(
          map(customerReturn =>
            CkCustomerReturn.deserialize(customerReturn, {
              ianaTimezone: this.#ianaTimezone(),
            }),
          ),
        ),
    });
  }

  @Action(CkCustomerReturnsGetMany)
  getMany(ctx: StateContext<CkCustomerReturnsStateModel>) {
    return this.#ckCustomerReturnsService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(
          ctx,
          data.map(customerReturn =>
            CkCustomerReturn.deserialize(customerReturn, {
              ianaTimezone: this.#ianaTimezone(),
            }),
          ),
        );
      }),
    );
  }

  @Action(CkCustomerReturnsCreate)
  create(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsCreate) {
    return this.#ckCustomerReturnsService
      .create(payload.body)
      .pipe(switchMap(({ id }) => ctx.dispatch(new CkCustomerReturnsGet({ id }))));
  }

  @Action(CkCustomerReturnsUpdate)
  update(_: StateContext<CkCustomerReturnsStateModel>, { payload: { id, body } }: CkCustomerReturnsUpdate) {
    return this.#ckCustomerReturnsService.update(id, body);
  }

  @Action(CkCustomerReturnsPost)
  post(_: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsPost) {
    return this.#ckCustomerReturnsService.post(id);
  }

  @Action(CkCustomerReturnsPostMany)
  postMany(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsPostMany) {
    return this.#ckCustomerReturnsService
      .postMany(payload)
      .pipe(tap(response => ctx.patchState({ postManyResponse: response })));
  }

  @Action(CkCustomerReturnsLock)
  lock(_: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsLock) {
    return this.#ckCustomerReturnsService.lock(id);
  }

  @Action(CkCustomerReturnsLockMany)
  lockMany(ctx: StateContext<CkCustomerReturnsStateModel>, { payload }: CkCustomerReturnsLockMany) {
    return this.#ckCustomerReturnsService
      .lockMany(payload)
      .pipe(switchMap(() => ctx.dispatch(new CkCustomerReturnsGetMany())));
  }

  @Action(CkCustomerReturnsUnlock)
  unlock(_: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsUnlock) {
    return this.#ckCustomerReturnsService.unlock(id);
  }

  @Action(CkCustomerReturnsApprove)
  approve(_: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsApprove) {
    return this.#ckCustomerReturnsService.approve(id);
  }

  @Action(CkCustomerReturnsApproveMany)
  approveMany(ctx: StateContext<CkCustomerReturnsStateModel>, { body }: CkCustomerReturnsApproveMany) {
    return this.#ckCustomerReturnsService
      .approveMany(body)
      .pipe(switchMap(() => ctx.dispatch(new CkCustomerReturnsGetMany())));
  }

  @Action(CkCustomerReturnsDiscard)
  discard(_: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsDiscard) {
    return this.#ckCustomerReturnsService.discard(id);
  }

  @Action(CkCustomerReturnsDiscardMany)
  discardMany(ctx: StateContext<CkCustomerReturnsStateModel>, { body }: CkCustomerReturnsDiscardMany) {
    return this.#ckCustomerReturnsService
      .discardMany(body)
      .pipe(switchMap(() => ctx.dispatch(new CkCustomerReturnsGetMany())));
  }

  @Action(CkCustomerReturnsGenerateDocumentNumber)
  generateDocumentNumber(ctx: StateContext<CkCustomerReturnsStateModel>) {
    return this.#store.selectOnce(CurrentRetailerState.get).pipe(
      switchMap(retailerId => this.#ckCustomerReturnsService.generateDocumentNumber({ retailer: { id: retailerId } })),
      tap(({ number }) => ctx.patchState({ generatedDocumentNumber: number })),
    );
  }

  @Action(CkCustomerReturnsDownloadPdf)
  downloadPdf(_: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsDownloadPdf) {
    return this.#ckCustomerReturnsService.downloadPdf(id).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl, '_blank');
      }),
    );
  }

  @Action(CkCustomerReturnsExportList, { cancelUncompleted: true })
  exportList(ctx: StateContext<CkCustomerReturnsStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.#ckCustomerReturnsService.getMany(query).pipe(
      tap(({ data }) => {
        ctx.patchState({
          exportLoading: false,
        });

        void downloadCkCustomerReturnsList(
          data.map(customerReturn =>
            CkCustomerReturn.deserialize(customerReturn, {
              ianaTimezone: this.#ianaTimezone(),
            }),
          ),
          this.#currency(),
          this.#hidePrices(),
        );
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  @Action(CkCustomerReturensNavigateToList)
  navigateToList(ctx: StateContext<CkCustomerReturnsStateModel>) {
    return ctx.dispatch(new Navigate(['/central-kitchen', 'customer-returns']));
  }

  @Action(CkCustomerReturnsNavigateToDetails)
  navigateToDetails(ctx: StateContext<CkCustomerReturnsStateModel>, { id }: CkCustomerReturnsNavigateToDetails) {
    return ctx.dispatch(new Navigate(['/central-kitchen', 'customer-returns', id]));
  }

  private getQuery(
    state: CkCustomerReturnsStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<CkCustomerReturnQueryProps> {
    const { start, end, status, customerIds, centralKitchenId, documentNumber } = state.filters;

    const qb = new QueryBuilder<CkCustomerReturnQueryProps>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ?? state.requestMetadata.limit,
        offset: extras.offset ?? state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'document.documentDate',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (customerIds?.length) {
      qb.filtering.setFilter({
        by: 'customer.id',
        match: customerIds,
        op: 'in',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'supplier.metadata.retailerId',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (centralKitchenId) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: centralKitchenId,
        op: 'eq',
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (documentNumber) {
      qb.filtering.setFilter({
        by: 'document.number',
        match: documentNumber,
        op: 'like',
      });
    }

    if (start && end && !documentNumber) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        {
          by: 'document.documentDate',
          op: 'lte',
          match: getShiftedDate(new Date(end), utcOffset).getTime(),
        },
        {
          by: 'document.documentDate',
          op: 'gte',
          match: getShiftedDate(new Date(start), utcOffset).getTime(),
        },
      ]);
    }

    return qb.build();
  }
}
