import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { ShippingInvoiceType } from '@supy/settings';

import { CkCustomerGroupResponse } from './ck-customer-group.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerGroupResponse>();

export abstract class CkCustomerGroupBase {
  protected constructor(args: NonFunctionProperties<CkCustomerGroupBase>) {
    this.name = args.name;
    this.defaultTaxCode = args.defaultTaxCode;
    this.shippingInvoiceType = args.shippingInvoiceType;
  }

  @ApiProperty() readonly name: string;
  @ApiProperty() readonly defaultTaxCode: Nullable<string>;
  @ApiProperty() readonly shippingInvoiceType: ShippingInvoiceType;
}

export class CkCustomerGroup extends CkCustomerGroupBase {
  private constructor(args: NonFunctionProperties<CkCustomerGroup>) {
    super(args);
    this.id = args.id;
  }

  @ApiProperty() readonly id: string;

  static deserialize(data: CkCustomerGroupResponse): CkCustomerGroup {
    return new CkCustomerGroup({
      id: data.id,
      name: data.name,
      defaultTaxCode: data.defaultTaxCode ?? null,
      shippingInvoiceType: data.shippingInvoiceType,
    });
  }

  static deserializeList(data: CkCustomerGroupResponse[]): CkCustomerGroup[] {
    return data.map(this.deserialize);
  }
}
