import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { CK_ITEMS_BFF_URI } from '../config';
import {
  CkCatalogItemCreatePayload,
  CkCatalogItemResponse,
  CkCatalogItemsQueryProps,
  CkCatalogItemUpdatePayload,
  CkCatalogPackagingResponse,
  CkCatalogPackagingsQueryProps,
  CkDeleteManyRequest,
} from '../core';

@Injectable()
export class CkItemsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uriBff = inject(CK_ITEMS_BFF_URI);

  get(id: string): Observable<CkCatalogItemResponse> {
    return this.#httpClient.get<CkCatalogItemResponse>(`${this.#uriBff}/${id}`);
  }

  getCatalogItems(
    query: Query<CkCatalogItemsQueryProps>,
    options?: {
      readonly matched?: boolean;
      readonly shipDate?: Date;
    },
  ): Observable<IQueryResponse<CkCatalogItemResponse>> {
    const { matched, shipDate } = options ?? {};

    return this.#httpClient.get<IQueryResponse<CkCatalogItemResponse>>(`${this.#uriBff}${matched ? '/matched' : ''}`, {
      params: shipDate
        ? {
            ...query.toQueryParams(),
            costingDate: shipDate.toISOString(),
          }
        : query.toQueryParams(),
    });
  }

  getPackagings(query: Query<CkCatalogPackagingsQueryProps>): Observable<IQueryResponse<CkCatalogPackagingResponse>> {
    return this.#httpClient.get<IQueryResponse<CkCatalogPackagingResponse>>(`${this.#uriBff}/without-price-list`, {
      params: query.toQueryParams(),
    });
  }

  create(payload: CkCatalogItemCreatePayload): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}`, payload.serialize());
  }

  update(id: string, payload: CkCatalogItemUpdatePayload): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}`, payload.serialize());
  }

  deleteMany(payload: CkDeleteManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}`, payload);
  }
}
