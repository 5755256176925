import { getApiDetailsDecorator, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkPriceList } from '../price-list';
import {
  CkCatalogItemPackagingResponse,
  CkCatalogItemResponse,
  CkCatalogItemRetailerItemResponse,
} from './ck-catalog-item.model';
import { CkCatalogPriceList } from './ck-catalog-price-list.entity';

const ApiProperty = getApiDetailsDecorator<CkCatalogItemResponse>();

type CkCatalogItemArgs = Omit<CkCatalogItem, 'category' | 'code' | 'name' | 'taxCode'>;

export class CkCatalogItem {
  protected constructor(args: NonFunctionProperties<CkCatalogItemArgs>) {
    this.id = args.id;
    this.cost = args.cost;
    this.priceLists = args.priceLists;
    this.quantity = args.quantity;
    this.packaging = args.packaging;
    this.retailerItem = args.retailerItem;

    // Computed properties
    this.category = this.retailerItem?.category?.name as LocalizedData;
    this.code = this.packaging.code;
    this.name = this.packaging.name;
    this.taxCode = this.retailerItem.taxCode ?? '';
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly cost: number;
  @ApiProperty() readonly priceLists: CkCatalogPriceList[];
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly packaging: CkCatalogItemPackagingResponse;
  @ApiProperty() readonly retailerItem: CkCatalogItemRetailerItemResponse;

  // Computed properties
  readonly category: LocalizedData | null;
  readonly code: string;
  readonly name: string;
  readonly taxCode?: string;

  static deserialize(data: CkCatalogItemResponse): CkCatalogItem {
    return new CkCatalogItem({
      id: data.id,
      cost: data.cost,
      priceLists: data.priceLists?.map(priceList => CkCatalogPriceList.deserialize(priceList)),
      quantity: data.quantity,
      packaging: data.packaging,
      retailerItem: data.retailerItem,
    });
  }

  static deserializeWithPriceLists(data: CkCatalogItemResponse, { priceLists }: DeserializedArgs): CkCatalogItem {
    const activePriceListIds = new Set(data.priceLists.map(({ id }) => id));
    const inactivePriceLists = priceLists.filter(({ id }) => !activePriceListIds.has(id));

    return new CkCatalogItem({
      ...this.deserialize(data),
      priceLists: data.priceLists
        .map(priceList => CkCatalogPriceList.deserialize(priceList))
        .concat(inactivePriceLists.map(priceList => CkCatalogPriceList.from(priceList))),
    });
  }
}
interface DeserializedArgs {
  readonly priceLists: CkPriceList[];
}

export type EditableCkCatalogItem = Pick<
  CkCatalogItem,
  'id' | 'name' | 'quantity' | 'retailerItem' | 'packaging' | 'priceLists'
> & {
  readonly price: number;
};
