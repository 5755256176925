import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { CreationSourceEnum, DiscountType } from '@supy/orders';
import { getLocalizedName, TaxRate } from '@supy/settings';

import {
  CkCatalogItemPackagingResponse,
  CkCatalogItemPricingMethod,
  CkCatalogItemRetailerItemResponse,
  CkCatalogPriceList,
  EditableCkCatalogItem,
} from '../catalog';
import { CkCustomer } from '../customer';
import { CkOrderItemB2bResponse } from './ck-order-item-b2b.model';

const ApiProperty = getApiDetailsDecorator<CkOrderItemB2bResponse>();

export class CkOrderItemB2b {
  constructor(args: NonFunctionProperties<CkOrderItemB2b>) {
    this.catalogItemId = args.catalogItemId;
    this.code = args.code;
    this.confirmedQuantity = args.confirmedQuantity;
    this.discountAmount = args.discountAmount;
    this.discountType = args.discountType;
    this.id = args.id;
    this.name = args.name;
    this.packaging = args.packaging;
    this.price = args.price;
    this.priceLists = args.priceLists;
    this.pricingMethod = args.pricingMethod;
    this.quantity = args.quantity;
    this.retailerItem = args.retailerItem;
    this.tax = args.tax;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly retailerItem: CkCatalogItemRetailerItemResponse;
  @ApiProperty({ key: 'appliedTax' }) readonly tax: TaxRate;
  @ApiProperty({ key: 'itemCode' }) readonly code: string;
  @ApiProperty() readonly packaging: CkCatalogItemPackagingResponse;
  readonly catalogItemId: string;
  readonly confirmedQuantity: number;
  readonly discountAmount: number;
  readonly discountType: DiscountType;
  readonly price: number;
  readonly priceLists: CkCatalogPriceList[];
  readonly pricingMethod: Nullable<CkCatalogItemPricingMethod>;
  readonly quantity: number;
  readonly receivedQuantity: number;

  static deserialize(
    data: CkOrderItemB2bResponse,
    { creationSource }: { readonly creationSource: CreationSourceEnum },
  ): CkOrderItemB2b {
    return new CkOrderItemB2b({
      catalogItemId: data.id,
      code: data.packaging.code ?? data.itemCode,
      confirmedQuantity: data.quantity.modified ?? data.quantity.ordered ?? 0,
      discountAmount: data.discount?.amount ?? 0,
      discountType: data.discount?.type ?? DiscountType.Value,
      id: data.id,
      name: getLocalizedName(data.name),
      price: data.price.modified ?? data.price.ordered,
      priceLists: [],
      pricingMethod: null,
      quantity: creationSource === CreationSourceEnum.CentralKitchen ? data.quantity.modified : data.quantity.ordered,
      receivedQuantity: data.quantity.received ?? 0,
      retailerItem: data.retailerItem,
      tax: data.appliedTax,
      packaging: data.packaging,
    });
  }

  static fromCatalogItem(data: EditableCkCatalogItem, args: FromCatalogItemDeserializeArgs): CkOrderItemB2b {
    const defaultTax = args.taxes.find(t => t.taxCode === args.customer?.customerGroup?.defaultTaxCode);
    const tax =
      defaultTax ??
      args.taxes.find(({ taxCode }) => data.retailerItem?.taxCode === taxCode) ??
      args.defaultOnReceivingTax ??
      args.exemptTax;

    return new CkOrderItemB2b({
      catalogItemId: data.id,
      code: data.packaging.code,
      confirmedQuantity: data.quantity,
      discountAmount: 0,
      discountType: DiscountType.Value,
      id: crypto.randomUUID(),
      name: data.name,
      price: data.price ?? 0,
      priceLists: data.priceLists,
      pricingMethod: data.priceLists.find(({ id }) => id === args.customer?.priceList?.id)
        ? null
        : CkCatalogItemPricingMethod.ManualInput,
      quantity: data.quantity,
      receivedQuantity: 0,
      retailerItem: data.retailerItem,
      tax,
      packaging: data.packaging,
    });
  }

  static computeTotalPriceAfterDiscount(item: CkOrderItemB2b): number {
    const itemTotalPrice = Math.max(0, item.price) * Math.max(0, item.confirmedQuantity || item.quantity);
    const safeDiscountAmount = Math.max(0, item.discountAmount);

    if (safeDiscountAmount) {
      return item.discountType === DiscountType.Value
        ? itemTotalPrice - safeDiscountAmount
        : itemTotalPrice - itemTotalPrice * (safeDiscountAmount / 100);
    }

    return itemTotalPrice;
  }

  static computeTotalPricePreTax({ item, invoiceAdjustment }: PriceCalculationArgs): number {
    const discountedTotalPrice = this.computeTotalPriceAfterDiscount(item);

    return discountedTotalPrice - discountedTotalPrice * invoiceAdjustment;
  }

  static computeTotalPriceAfterTax(args: PriceCalculationArgs): number {
    return this.computeTotalPricePreTax(args) + this.computeTaxAmount(args);
  }

  static computeTaxAmount({ item, invoiceAdjustment }: PriceCalculationArgs): number {
    return item.tax
      ? (item.tax.rate / 100) *
          this.computeTotalPricePreTax({
            item,
            invoiceAdjustment,
          })
      : 0;
  }
}

interface PriceCalculationArgs {
  readonly item: CkOrderItemB2b;
  readonly invoiceAdjustment: number;
}

interface FromCatalogItemDeserializeArgs {
  readonly defaultOnReceivingTax: TaxRate;
  readonly exemptTax: TaxRate;
  readonly taxes: TaxRate[];
  readonly customer: CkCustomer;
}
