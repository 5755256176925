import produce from 'immer';
import { tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { BaseResponseMetadata, EntityListState, EntityListStateModel, Query, QueryBuilder } from '@supy/common';

import {
  CkCustomerGroup,
  CkCustomerGroupsQueryProps,
  CkCustomerGroupsRequestMetadata,
  CkCustomerGroupState,
} from '../../core';
import { CkCustomerGroupsService } from '../../services';
import {
  CkCustomerGroupsCreate,
  CkCustomerGroupsDeleteMany,
  CkCustomerGroupsGetMany,
  CkCustomerGroupsInitFilters,
  CkCustomerGroupsPatchFilters,
  CkCustomerGroupsPatchRequestMetadata,
  CkCustomerGroupsResetFilters,
  CkCustomerGroupsUpdate,
} from '../actions';

export interface CkCustomerGroupsFilters {
  readonly search: string;
}

export interface CkCustomerGroupsStateModel extends EntityListStateModel<CkCustomerGroup> {
  readonly filters: CkCustomerGroupsFilters;
  readonly requestMetadata: CkCustomerGroupsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkCustomerGroupsFilters = {
  search: null,
};

const DEFAULT_REQUEST_METADATA: CkCustomerGroupsRequestMetadata = {
  retailerId: null,
  page: 0,
  limit: 25,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_CUSTOMER_GROUPS_STATE_TOKEN = new StateToken<CkCustomerGroupsStateModel>('ckCustomerGroups');

@State<CkCustomerGroupsStateModel>({
  name: CK_CUSTOMER_GROUPS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class CkCustomerGroupsState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #customerGroupsService = inject(CkCustomerGroupsService);

  @Selector() static customerGroups(state: CkCustomerGroupsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static filters(state: CkCustomerGroupsStateModel) {
    return EntityListState.currentFilters<CkCustomerGroupsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkCustomerGroupsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkCustomerGroupsStateModel) {
    return state.responseMetadata;
  }

  @Action(CkCustomerGroupsInitFilters)
  initFilters(ctx: StateContext<CkCustomerGroupsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkCustomerGroupsPatchFilters)
  patchFilters(ctx: StateContext<CkCustomerGroupsStateModel>, { payload }: CkCustomerGroupsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkCustomerGroupsPatchRequestMetadata({ page: 0 }), new CkCustomerGroupsGetMany()]);
  }

  @Action(CkCustomerGroupsResetFilters)
  resetFilters(ctx: StateContext<CkCustomerGroupsStateModel>) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
    });
  }

  @Action(CkCustomerGroupsPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<CkCustomerGroupsStateModel>,
    { payload }: CkCustomerGroupsPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkCustomerGroupsGetMany)
  getMany(ctx: StateContext<CkCustomerGroupsStateModel>) {
    return this.#customerGroupsService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({
          responseMetadata: metadata,
        });
        super.setMany(ctx, CkCustomerGroup.deserializeList(data));
      }),
    );
  }

  @Action(CkCustomerGroupsCreate)
  create(_: StateContext<CkCustomerGroupsStateModel>, { payload }: CkCustomerGroupsCreate) {
    return this.#customerGroupsService.create(payload);
  }

  @Action(CkCustomerGroupsUpdate)
  update(_: StateContext<CkCustomerGroupsStateModel>, { id, payload }: CkCustomerGroupsUpdate) {
    return this.#customerGroupsService.update(id, payload);
  }

  @Action(CkCustomerGroupsDeleteMany)
  deleteMany(_: StateContext<CkCustomerGroupsStateModel>, { payload }: CkCustomerGroupsDeleteMany) {
    return this.#customerGroupsService.deleteMany(payload);
  }

  private getQuery({ filters, requestMetadata }: CkCustomerGroupsStateModel): Query<CkCustomerGroupsQueryProps> {
    const qb = new QueryBuilder<CkCustomerGroupsQueryProps>({
      filtering: [],
      paging: { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
    });

    qb.filtering.setFilter({
      by: 'state',
      match: CkCustomerGroupState.Available,
      op: 'eq',
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (filters.search) {
      qb.filtering.setFilter({
        by: 'name',
        match: filters.search,
        op: 'like',
      });
    }

    return qb.build();
  }
}
