import { getApiDetailsDecorator, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { OrderStatus, SimpleEntity, StatefulOrderEntity } from '@supy/orders';
import { Packaging } from '@supy/packaging';

import { CkAggregatedOrder } from './ck-aggregated-order.entity';
import { CkOrderItemQuantity, CkOrderItemResponse, CkOrderItemType } from './ck-order-item.model';

const ApiProperty = getApiDetailsDecorator<CkOrderItemResponse>();

type CkOrderItemArgs = NonFunctionProperties<
  Omit<
    CkOrderItem,
    | 'actualPrice'
    | 'confirmedQuantity'
    | 'id'
    | 'isSubmitted'
    | 'modifiedPrice'
    | 'orderedPrice'
    | 'orderedQuantity'
    | 'pricePerItem'
    | 'projectedStockQuantity'
    | 'stockQuantity'
  >
>;

export class CkOrderItem {
  private constructor(args: CkOrderItemArgs) {
    this.category = args.category;
    this.code = args.code;
    this.deliveryDate = args.deliveryDate;
    this.expectingStockQuantity = args.expectingStockQuantity;
    this.name = args.name;
    this.orderItemIds = args.orderItemIds;
    this.orders = args.orders;
    this.price = args.price;
    this.quantity = args.quantity;
    this.status = args.status;
    this.type = args.type;
    this.packaging = args.packaging;

    // Computed properties
    this.orderedPrice = this.price.ordered;
    this.modifiedPrice = this.price.modified;
    this.isSubmitted = this.status === OrderStatus.Submitted;
    this.actualPrice = this.isSubmitted ? this.orderedPrice : this.modifiedPrice;
    this.confirmedQuantity = this.isSubmitted ? this.quantity.ordered : this.quantity.modified;
    this.id = this.orderItemIds[0];
    this.orderedQuantity = this.quantity.ordered ?? this.quantity.modified;
    this.pricePerItem = this.actualPrice / this.confirmedQuantity;
    this.stockQuantity = this.quantity.stock;
    this.projectedStockQuantity = this.stockQuantity - this.confirmedQuantity;
  }

  @ApiProperty() readonly category: SimpleEntity;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly name: LocalizedData;
  @ApiProperty() readonly orderItemIds: string[];
  @ApiProperty() readonly orders: CkAggregatedOrder[];
  @ApiProperty() readonly price: StatefulOrderEntity;
  @ApiProperty() readonly quantity: CkOrderItemQuantity;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty() readonly type: CkOrderItemType;
  @ApiProperty({ key: 'retailerItemCode' }) readonly code: string;
  @ApiProperty() readonly packaging: Packaging;

  // Computed properties
  readonly actualPrice: number;
  readonly confirmedQuantity: number;
  readonly expectingStockQuantity: number;
  readonly id: string;
  readonly isSubmitted: boolean;
  readonly modifiedPrice: number;
  readonly orderedPrice: number;
  readonly orderedQuantity: number;
  readonly pricePerItem: number;
  readonly projectedStockQuantity: number;
  readonly stockQuantity: number;

  static deserialize(data: CkOrderItemResponse): CkOrderItem {
    return new CkOrderItem({
      category: data.category,
      code: data.retailerItemCode,
      deliveryDate: data.deliveryDate && new Date(data.deliveryDate),
      expectingStockQuantity: data.quantity.expectingStock,
      name: data.name,
      orderItemIds: data.orderItemIds,
      orders: CkAggregatedOrder.deserializeList(data.orders),
      price: data.price,
      quantity: data.quantity,
      status: data.status,
      type: data.type,
      packaging: data.packaging,
    });
  }

  static deserializeList(data: CkOrderItemResponse[]): CkOrderItem[] {
    return data.map(orderItem => this.deserialize(orderItem));
  }
}
