import produce from 'immer';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  isInstanceOf,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';
import { PackagingState } from '@supy/packaging';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import {
  CkCatalogItem,
  CkCatalogItemsQueryProps,
  CkCatalogItemsRequestMetadata,
  CkCatalogItemState,
  CkCatalogPackaging,
  CkCatalogPackagingsQueryProps,
} from '../../core';
import { downloadCkCatalogItemsList } from '../../helpers';
import { CkItemsService } from '../../services';
import {
  CkCatalogItemCreate,
  CkCatalogItemsDeleteMany,
  CkCatalogItemsExport,
  CkCatalogItemsGet,
  CkCatalogItemsGetMany,
  CkCatalogItemsInitFilters,
  CkCatalogItemsPatchFilters,
  CkCatalogItemsPatchRequestMetadata,
  CkCatalogItemsResetFilters,
  CkCatalogItemUpdate,
  CkCatalogPackagingsGetMany,
  CkPriceListsGetMany,
  CkPriceListsPatchRequestMetadata,
} from '../actions';
import { CkPriceListsState } from './ck-price-lists.state';

export interface CkCatalogItemsFilters {
  readonly searchByPackagingId?: string;
  readonly search: string;
  readonly centralKitchenId: string;
  readonly itemCategoryIds: string[];
  readonly showItemsWithoutPriceLists: boolean | null;
  readonly catalogItemIds?: string[];
  readonly priceListId?: string;
  readonly ids?: string[];
}

export interface CkCatalogItemsStateModel extends EntityListStateModel<CkCatalogItem | CkCatalogPackaging> {
  readonly filters: CkCatalogItemsFilters;
  readonly requestMetadata: CkCatalogItemsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkCatalogItemsFilters = {
  search: null,
  centralKitchenId: null,
  itemCategoryIds: [],
  showItemsWithoutPriceLists: null,
  ids: [],
};

const DEFAULT_REQUEST_METADATA: CkCatalogItemsRequestMetadata = {
  retailerId: null,
  warehouseId: null,
  page: 0,
  limit: 25,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_CATALOG_Items_STATE_TOKEN = new StateToken<CkCatalogItemsStateModel>('ckCatalogItems');

@State<CkCatalogItemsStateModel>({
  name: CK_CATALOG_Items_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class CkCatalogItemsState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #cKItemsService = inject(CkItemsService);
  readonly #store = inject(Store);
  readonly #hidePrices = this.#store.selectSignal(CurrentUserState.hideApproxPrice);

  @Selector() static items(state: CkCatalogItemsStateModel): CkCatalogItem[] {
    return EntityListState.all(state).filter(isInstanceOf(CkCatalogItem));
  }

  @Selector() static packagings(state: CkCatalogItemsStateModel): CkCatalogPackaging[] {
    return EntityListState.all(state).filter(isInstanceOf(CkCatalogPackaging));
  }

  static item(id: string, next?: boolean) {
    return createSelector([CK_CATALOG_Items_STATE_TOKEN], (state: CkCatalogItemsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static currentItem(state: CkCatalogItemsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkCatalogItemsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkCatalogItemsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static filters(state: CkCatalogItemsStateModel) {
    return EntityListState.currentFilters<CkCatalogItemsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkCatalogItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkCatalogItemsStateModel) {
    return state.responseMetadata;
  }

  @Action(CkCatalogItemsInitFilters)
  initFilters(ctx: StateContext<CkCatalogItemsStateModel>, { options }: CkCatalogItemsInitFilters) {
    super.initFilter(ctx, DEFAULT_FILTERS, options);
  }

  @Action(CkCatalogItemsPatchFilters)
  patchFilters(ctx: StateContext<CkCatalogItemsStateModel>, { payload, options }: CkCatalogItemsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS, options);
    ctx.dispatch([new CkCatalogItemsPatchRequestMetadata({ page: 0 })]);
  }

  @Action(CkCatalogItemsResetFilters)
  resetFilters(ctx: StateContext<CkCatalogItemsStateModel>, { options }: CkCatalogItemsResetFilters) {
    super.resetFilter(
      ctx,
      {
        ...DEFAULT_FILTERS,
        centralKitchenId: ctx.getState().filters.centralKitchenId,
      },
      options,
    );
  }

  @Action(CkCatalogItemsPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<CkCatalogItemsStateModel>, { payload }: CkCatalogItemsPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkCatalogItemsGetMany, { cancelUncompleted: true })
  getMany(ctx: StateContext<CkCatalogItemsStateModel>, { options }: CkCatalogItemsGetMany) {
    return this.#cKItemsService.getCatalogItems(this.getCatalogItemsQuery(ctx.getState()), options).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({
          responseMetadata: metadata,
        });
        super.setMany(
          ctx,
          data.map(catalogItem => CkCatalogItem.deserialize(catalogItem)),
        );
      }),
    );
  }

  @Action(CkCatalogPackagingsGetMany, { cancelUncompleted: true })
  getPackagings(ctx: StateContext<CkCatalogItemsStateModel>) {
    const { retailerId } = ctx.getState().requestMetadata;

    return this.#store.dispatch([new CkPriceListsPatchRequestMetadata({ retailerId }), new CkPriceListsGetMany()]).pipe(
      switchMap(() => this.#store.selectOnce(CkPriceListsState.priceLists)),
      switchMap(priceLists =>
        this.#cKItemsService.getPackagings(this.getCatalogPackagingsQuery(ctx.getState())).pipe(
          tap(({ data, metadata }) => {
            ctx.patchState({
              responseMetadata: metadata,
            });
            super.setMany(
              ctx,
              data.map(catalogItem => CkCatalogPackaging.deserialize(catalogItem, { priceLists })),
            );
          }),
        ),
      ),
    );
  }

  @Action(CkCatalogItemsGet)
  get(ctx: StateContext<CkCatalogItemsStateModel>, { payload }: CkCatalogItemsGet) {
    const { retailerId } = ctx.getState().requestMetadata;

    return super.getOne(ctx, {
      id: payload.id,
      fromCache: payload.fromCache,
      fetchApi: () =>
        this.#store.dispatch([new CkPriceListsPatchRequestMetadata({ retailerId }), new CkPriceListsGetMany()]).pipe(
          switchMap(() => this.#store.selectOnce(CkPriceListsState.priceLists)),
          switchMap(priceLists =>
            this.#cKItemsService
              .get(payload.id)
              .pipe(map(response => CkCatalogItem.deserializeWithPriceLists(response, { priceLists }))),
          ),
        ),
    });
  }

  @Action(CkCatalogItemCreate)
  create(_: StateContext<CkCatalogItemsStateModel>, { payload }: CkCatalogItemCreate) {
    return this.#cKItemsService.create(payload);
  }

  @Action(CkCatalogItemUpdate)
  update(_: StateContext<CkCatalogItemsStateModel>, { id, payload }: CkCatalogItemUpdate) {
    return this.#cKItemsService.update(id, payload);
  }

  @Action(CkCatalogItemsDeleteMany)
  deleteMany(_: StateContext<CkCatalogItemsStateModel>, { payload }: CkCatalogItemsDeleteMany) {
    return this.#cKItemsService.deleteMany(payload);
  }

  @Action(CkCatalogItemsExport)
  export(ctx: StateContext<CkCatalogItemsStateModel>) {
    const state = ctx.getState();
    const query = this.getCatalogItemsQuery(state, {
      noLimit: true,
    });

    return this.#cKItemsService.getCatalogItems(query).pipe(
      withLatestFrom(this.#store.selectOnce(SettingsState.currency)),
      tap(([{ data }, currency]) => {
        void downloadCkCatalogItemsList(
          data.map(catalogItem => CkCatalogItem.deserialize(catalogItem)),
          { currency, hidePrices: this.#hidePrices() },
        );
      }),
    );
  }

  private getCatalogItemsQuery(
    { filters, requestMetadata }: CkCatalogItemsStateModel,
    options?: { noLimit: boolean },
  ): Query<CkCatalogItemsQueryProps> {
    const qb = new QueryBuilder<CkCatalogItemsQueryProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
      ordering: [
        {
          by: 'category.id',
          dir: 'asc',
        },
        {
          by: 'packaging.name',
          dir: 'asc',
        },
      ],
    });

    qb.filtering.setFilter({
      by: 'state',
      match: CkCatalogItemState.Available,
      op: 'eq',
    });

    if (filters?.priceListId) {
      qb.filtering.setFilter({
        by: 'priceLists.id',
        match: filters.priceListId,
        op: 'eq',
      });
    }

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (requestMetadata.warehouseId) {
      qb.filtering.setFilter({
        by: 'centralKitchen.supplierId',
        match: requestMetadata.warehouseId,
        op: 'eq',
      });
    }

    if (filters.ids?.length > 0) {
      qb.filtering.setFilter({
        by: 'id',
        match: filters.ids,
        op: 'in',
      });
    }

    if (filters.search) {
      qb.filtering.setFilter({
        by: 'packaging.name',
        match: filters.search,
        op: 'like',
      });
    }

    if (filters.searchByPackagingId) {
      qb.filtering.setFilter({
        by: 'packaging.id',
        match: filters.searchByPackagingId,
        op: 'eq',
      });
    }

    if (filters.catalogItemIds?.length) {
      qb.filtering.setFilter({
        by: 'id',
        match: filters.catalogItemIds,
        op: 'not-in',
      });
    }

    if (filters.centralKitchenId) {
      qb.filtering.setFilter({
        by: 'centralKitchen.locationId',
        match: filters.centralKitchenId,
        op: 'eq',
      });
    }

    if (filters.itemCategoryIds?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: decodeURIComponent(filters.itemCategoryIds as unknown as string).split(','),
        op: 'in',
      });
    }

    return qb.build();
  }

  private getCatalogPackagingsQuery(
    { filters, requestMetadata }: CkCatalogItemsStateModel,
    options?: { noLimit: boolean; priceListId: string },
  ): Query<CkCatalogPackagingsQueryProps> {
    const qb = new QueryBuilder<CkCatalogPackagingsQueryProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
      ordering: [
        {
          by: 'category.id',
          dir: 'asc',
        },
        {
          by: 'fullPackageName',
          dir: 'asc',
        },
      ],
    });

    qb.filtering.setFilter({
      by: 'state',
      match: PackagingState.Active,
      op: 'eq',
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (filters.search) {
      qb.filtering.setFilter({
        by: 'fullPackageName',
        match: filters.search,
        op: 'like',
      });
    }

    if (filters.centralKitchenId) {
      qb.filtering.setFilter({
        by: 'centralKitchen.locationId',
        match: filters.centralKitchenId,
        op: 'eq',
      });
    }

    if (filters.itemCategoryIds?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: decodeURIComponent(filters.itemCategoryIds as unknown as string).split(','),
        op: 'in',
      });
    }

    if (filters.ids?.length) {
      qb.filtering.setFilter({
        by: 'id',
        match: filters.ids,
        op: 'in',
      });
    }

    return qb.build();
  }
}
