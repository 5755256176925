import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';
import { ORDERS_BFF_URI } from '@supy/orders';

import {
  CkOrderB2bResponse,
  CkOrderItemsRequestProps,
  CkOrderRequestProps,
  CkOrderResponse,
  CreateCkOrderB2bPayload,
  GetCkOrderItemsResponse,
  UpdateCkOrderB2bPayload,
} from '../core';

@Injectable()
export class CkOrdersService {
  readonly #httpClient = inject(HttpClient);
  readonly #uriBff = inject(ORDERS_BFF_URI);

  getMany(query: Query<CkOrderRequestProps>): Observable<IQueryResponse<CkOrderResponse>> {
    return this.#httpClient.post<IQueryResponse<CkOrderResponse>>(`${this.#uriBff}/ck/get`, query);
  }

  getItems(query: Query<CkOrderItemsRequestProps>): Observable<GetCkOrderItemsResponse> {
    return this.#httpClient.post<GetCkOrderItemsResponse>(`${this.#uriBff}/by-items`, query);
  }

  getB2b(id: string): Observable<CkOrderB2bResponse> {
    return this.#httpClient.get<CkOrderB2bResponse>(`${this.#uriBff}/ck/${id}/detailed`);
  }

  create(payload: CreateCkOrderB2bPayload): Observable<CkOrderB2bResponse> {
    return this.#httpClient.post<CkOrderB2bResponse>(`${this.#uriBff}/ck`, payload.serialize());
  }

  update(orderId: string, payload: UpdateCkOrderB2bPayload): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${orderId}/ck`, payload.serialize());
  }
}
