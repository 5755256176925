<div class="ck-widget">
  <div class="ck-widget--initial-loading" *ngIf="isInitialLoading"></div>
  <h6 class="ck-widget__header">{{ header }}</h6>
  <h6 *ngIf="content; else widgetContent" class="ck-widget__content">
    {{ content }}
  </h6>
  <ng-template #widgetContent>
    <ng-content />
  </ng-template>
</div>
