import produce from 'immer';
import { groupBy } from 'lodash-es';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';
import { DropdownTreeNode } from '@supy/components';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import {
  CkCustomer,
  CkCustomerAutoComplete,
  CkCustomerAutoCompleteQueryProps,
  CkCustomersQueryProps,
  CkCustomersRequestMetadata,
} from '../../core';
import { downloadCkCustomersList } from '../../helpers';
import { CkCustomersService } from '../../services';
import {
  CkCustomersAutoComplete,
  CkCustomersCreate,
  CkCustomersDeleteMany,
  CkCustomersExport,
  CkCustomersGet,
  CkCustomersGetMany,
  CkCustomersInitFilters,
  CkCustomersPatchFilters,
  CkCustomersPatchRequestMetadata,
  CkCustomersResetFilters,
  CkCustomersUpdate,
} from '../actions';

export interface CkCustomersFilters {
  readonly search: string;
  readonly customerGroupIds: string[];
}

export interface CkCustomersStateModel extends EntityListStateModel<CkCustomer> {
  readonly autoCompleteList: CkCustomerAutoComplete[];
  readonly filters: CkCustomersFilters;
  readonly requestMetadata: CkCustomersRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkCustomersFilters = {
  search: null,
  customerGroupIds: [],
};

const DEFAULT_REQUEST_METADATA: CkCustomersRequestMetadata = {
  retailerId: null,
  page: 0,
  limit: 25,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_CUSTOMERS_STATE_TOKEN = new StateToken<CkCustomersStateModel>('ckCustomers');

@State<CkCustomersStateModel>({
  name: CK_CUSTOMERS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
    autoCompleteList: [],
  },
})
@Injectable()
export class CkCustomersState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #customersService = inject(CkCustomersService);
  readonly #store = inject(Store);
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));

  @Selector() static customers(state: CkCustomersStateModel) {
    return EntityListState.all(state);
  }

  static customer(id: string, next?: boolean) {
    return createSelector([CkCustomersState], (state: CkCustomersStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static currentCustomer(state: CkCustomersStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkCustomersStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkCustomersStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static filters(state: CkCustomersStateModel) {
    return EntityListState.currentFilters<CkCustomersFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkCustomersStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkCustomersStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static autoCompleteList(state: CkCustomersStateModel) {
    return state.autoCompleteList;
  }

  @Selector()
  static asTree(state: CkCustomersStateModel) {
    const customers: DropdownTreeNode<string>[] = [];
    const groupedCustomers = groupBy(state.entities, customer => customer.customerGroup.id);

    for (const customerGroupId in groupedCustomers) {
      const name = groupedCustomers[customerGroupId].find(({ customerGroup }) => customerGroup.id === customerGroupId)
        .customerGroup.name;

      customers.push({
        id: customerGroupId,
        name,
        children: groupedCustomers[customerGroupId].map(({ id, displayName }) => ({ id, name: displayName })),
        unselectable: true,
      });
    }

    return customers;
  }

  @Selector()
  static externalsAsTree(state: CkCustomersStateModel) {
    const customers: DropdownTreeNode<string>[] = [];
    const groupedCustomers = groupBy(
      state.entities.filter(({ isExternal }) => isExternal),
      customer => customer.customerGroup.id,
    );

    for (const customerGroupId in groupedCustomers) {
      const name = groupedCustomers[customerGroupId].find(({ customerGroup }) => customerGroup.id === customerGroupId)
        .customerGroup.name;

      customers.push({
        id: customerGroupId,
        name,
        children: groupedCustomers[customerGroupId].map(({ id, displayName }) => ({ id, name: displayName })),
        unselectable: true,
      });
    }

    return customers;
  }

  @Action(CkCustomersInitFilters)
  initFilters(ctx: StateContext<CkCustomersStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkCustomersPatchFilters)
  patchFilters(ctx: StateContext<CkCustomersStateModel>, { payload, options }: CkCustomersPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS, options);
    ctx.dispatch([new CkCustomersPatchRequestMetadata({ page: 0 }), new CkCustomersGetMany()]);
  }

  @Action(CkCustomersResetFilters)
  resetFilters(ctx: StateContext<CkCustomersStateModel>) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
    });
  }

  @Action(CkCustomersPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<CkCustomersStateModel>, { payload }: CkCustomersPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkCustomersGetMany)
  getMany(ctx: StateContext<CkCustomersStateModel>, { args }: CkCustomersGetMany) {
    return this.#customersService.getMany(this.getQuery(ctx.getState(), { noLimit: args?.noLimit })).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({
          responseMetadata: metadata,
        });
        super.setMany(
          ctx,
          CkCustomer.deserializeList(data).filter(({ isActive }) => isActive),
        );
      }),
    );
  }

  @Action(CkCustomersGet)
  get(ctx: StateContext<CkCustomersStateModel>, { payload }: CkCustomersGet) {
    return super.getOne(ctx, {
      id: payload.id,
      fetchApi: () => this.#customersService.get(payload.id).pipe(map(response => CkCustomer.deserialize(response))),
    });
  }

  @Action(CkCustomersCreate)
  create(ctx: StateContext<CkCustomersStateModel>, { payload }: CkCustomersCreate) {
    return this.#customersService.create(payload).pipe(
      tap(response => {
        ctx.setState(
          produce(draft => {
            const customer = CkCustomer.deserialize(response);

            draft.entities.push(customer);
            draft.sequence.id = customer.id;
          }),
        );
      }),
    );
  }

  @Action(CkCustomersUpdate)
  update(_: StateContext<CkCustomersStateModel>, { id, payload }: CkCustomersUpdate) {
    return this.#customersService.update(id, payload);
  }

  @Action(CkCustomersAutoComplete)
  autoComplete(ctx: StateContext<CkCustomersStateModel>, { term }: CkCustomersAutoComplete) {
    const { retailerId } = ctx.getState().requestMetadata;

    return this.#store.selectOnce(CurrentRetailerState.userCentralKitchens).pipe(
      switchMap(centralKitchens => {
        const query = new Query<CkCustomerAutoCompleteQueryProps>({
          filtering: [
            {
              by: 'retailer.id',
              match: retailerId,
              op: 'eq',
            },
            {
              by: 'centralKitchen.supplierId',
              match: centralKitchens.map(({ id }) => id),
              op: 'in',
            },
            {
              by: 'displayName',
              op: 'like',
              match: term,
            },
          ],
        });

        return this.#customersService.autoComplete(query);
      }),
      tap(({ data }) => {
        ctx.setState(
          produce(draft => {
            draft.autoCompleteList = CkCustomerAutoComplete.deserializeList(data);
          }),
        );
      }),
    );
  }

  @Action(CkCustomersDeleteMany)
  deleteMany(_: StateContext<CkCustomersStateModel>, { payload }: CkCustomersDeleteMany) {
    return this.#customersService.deleteMany(payload);
  }

  @Action(CkCustomersExport)
  export(ctx: StateContext<CkCustomersStateModel>) {
    const query = this.getQuery(ctx.getState(), {
      noLimit: true,
    });

    return this.#customersService.getMany(query).pipe(
      withLatestFrom(
        this.#store.selectOnce(SettingsState.currency),
        this.#store.selectOnce(SettingsState.ianaTimeZone),
      ),
      tap(([{ data }, currency, ianaTimeZone]) => {
        void downloadCkCustomersList(
          CkCustomer.deserializeList(data),
          { currency, hidePrices: this.#hidePrices() },
          ianaTimeZone,
        );
      }),
    );
  }

  private getQuery(
    { filters, requestMetadata }: CkCustomersStateModel,
    options?: { noLimit: boolean },
  ): Query<CkCustomersQueryProps> {
    const qb = new QueryBuilder<CkCustomersQueryProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (filters.customerGroupIds?.length) {
      qb.filtering.setFilter({
        by: 'customerGroup.id',
        match: filters.customerGroupIds,
        op: 'in',
      });
    }

    if (filters.search) {
      qb.filtering.withGroup('or', [
        {
          by: 'displayName',
          op: 'like',
          match: filters.search,
        },
        {
          by: 'code',
          op: 'like',
          match: filters.search,
        },
        {
          by: 'legalName',
          op: 'like',
          match: filters.search,
        },
      ]);
    }

    return qb.build();
  }
}
