import { getApiDetailsDecorator, isOneOf, Retailer, Supplier } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import {
  CreationSourceEnum,
  deserializeOrderActivity,
  DiscountType,
  OrderActivity,
  OrderStatus,
  OrderUpdateStatus,
} from '@supy/orders';

import { CkOrderCustomerSnapshot } from './ck-order.model';
import { CkOrderB2bResponse, CkOrderDiscount } from './ck-order-b2b.model';
import { CkOrderItemB2b } from './ck-order-item-b2b.entity';

const ApiProperty = getApiDetailsDecorator<CkOrderB2bResponse>();

type CkOrderB2bArgs = Omit<
  CkOrderB2b,
  | 'comments'
  | 'invoiceDiscountAmount'
  | 'invoiceDiscountType'
  | 'isActionable'
  | 'isCkCreationSource'
  | 'isConfirmable'
  | 'isDelivered'
  | 'isExternal'
  | 'isInternal'
  | 'isPartiallyReceived'
  | 'isRejectable'
  | 'isSaveable'
  | 'isShippable'
  | 'isShipped'
  | 'isSubmitted'
  | 'isUnshippable'
>;

export class CkOrderB2b {
  private constructor(args: NonFunctionProperties<CkOrderB2bArgs>) {
    this.activities = args.activities;
    this.centralKitchen = args.centralKitchen;
    this.creationSource = args.creationSource;
    this.customer = args.customer;
    this.deliveryDate = args.deliveryDate;
    this.discount = args.discount;
    this.externalDocNumber = args.externalDocNumber;
    this.id = args.id;
    this.invoiceDueDate = args.invoiceDueDate;
    this.items = args.items;
    this.number = args.number;
    this.otherFees = args.otherFees;
    this.retailer = args.retailer;
    this.shipDate = args.shipDate;
    this.status = args.status;

    // Computed properties
    this.invoiceDiscountAmount = this.discount?.amount;
    this.isSubmitted = this.status === OrderStatus.Submitted;
    this.isShipped = this.status === OrderStatus.Shipped;
    this.isShippable = this.status === OrderStatus.Confirmed;
    this.isSaveable = this.status === OrderStatus.Confirmed;
    this.isRejectable = isOneOf(this.status, [OrderStatus.Confirmed, OrderStatus.Submitted]);
    this.isConfirmable = this.status === OrderStatus.Submitted;
    this.isUnshippable = this.status === OrderStatus.Shipped;
    this.isDelivered = this.status === OrderStatus.Received;
    this.isPartiallyReceived = this.status === OrderStatus.PartialReceived;
    this.isActionable =
      this.isRejectable || this.isConfirmable || this.isSaveable || this.isShippable || this.isUnshippable;
    this.invoiceDiscountType = this.discount?.type;
    this.isCkCreationSource = this.creationSource === CreationSourceEnum.CentralKitchen;
    this.isExternal = this.retailer ? this.centralKitchen.metadata?.retailerId !== this.retailer.id : true;
    this.isInternal = this.retailer ? this.centralKitchen.metadata?.retailerId === this.retailer.id : false;
    this.comments = this.activities.reduce<string[]>((acc, { action, comment }) => {
      if (action === OrderUpdateStatus.Comment) {
        acc.push(comment);
      }

      return acc;
    }, []);
  }

  @ApiProperty() readonly activities: OrderActivity[];
  @ApiProperty() readonly creationSource: CreationSourceEnum;
  @ApiProperty() readonly customer: CkOrderCustomerSnapshot;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly discount: CkOrderDiscount | null;
  @ApiProperty() readonly externalDocNumber: string | null;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly invoiceDueDate: Date;
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly retailer: Retailer | null;
  @ApiProperty() readonly shipDate: Date;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty({ key: 'ckItems' }) readonly items: CkOrderItemB2b[];
  @ApiProperty({ key: 'fees' }) readonly otherFees: number;
  @ApiProperty({ key: 'supplier' }) readonly centralKitchen: Supplier;

  // Computed properties
  readonly comments: string[];
  readonly invoiceDiscountAmount?: number;
  readonly invoiceDiscountType?: DiscountType;
  readonly isActionable: boolean;
  readonly isCkCreationSource: boolean;
  readonly isConfirmable: boolean;
  readonly isDelivered: boolean;
  readonly isExternal: boolean;
  readonly isInternal: boolean;
  readonly isPartiallyReceived: boolean;
  readonly isRejectable: boolean;
  readonly isSaveable: boolean;
  readonly isShippable: boolean;
  readonly isShipped: boolean;
  readonly isSubmitted: boolean;
  readonly isUnshippable: boolean;

  static deserialize(data: CkOrderB2bResponse): CkOrderB2b {
    return new CkOrderB2b({
      activities: data.activities?.map(deserializeOrderActivity)?.reverse(),
      centralKitchen: data.supplier,
      creationSource: data.creationSource,
      customer: data.customer,
      deliveryDate: new Date(data.deliveryDate),
      discount: data.discount,
      externalDocNumber: data.externalDocNumber ?? null,
      id: data.id,
      invoiceDueDate: data.invoiceDueDate && new Date(data.invoiceDueDate),
      items: data.ckItems.map(ckItem => CkOrderItemB2b.deserialize(ckItem, { creationSource: data.creationSource })),
      number: data.number,
      otherFees: data.fees,
      retailer: data.retailer ?? null,
      shipDate: new Date(data.shipDate ?? data.deliveryDate),
      status: data.status,
    });
  }
}
